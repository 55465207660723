import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import { ApiRequests } from "../../service/ApiRequests";
import {
  catchAsync,
  handleLoadingErrorParamsForAsycThunk,
  reduxToolKitCaseBuilder,
} from "../../helpers/detectError";
import { toast } from "react-toastify";
import { getProjectAsyncThunk } from "./projectSlice";

export const createCommentAsyncThunk = createAsyncThunk(
  "comment/createCommentAsyncThunk",
  catchAsync(async ({ data, callBack }, { dispatch, getState }) => {
    const response = await ApiRequests.createComment(data);

    if (response.status === 201) {
      toast.success("Comment Created Successfully!");
      await dispatch(getProjectAsyncThunk({ id: data?.projectId}));
    }
    if (callBack) callBack();
    return response?.data;
  })
);

export const getCommentsAsyncThunk = createAsyncThunk(
  "comment/getCommentsAsyncThunk",
  catchAsync(async ({ params }, { dispatch, getState }) => {
    const response = await ApiRequests.getComments(params);
    return response?.data;
  })
);


///////////////////////////////////////////////////

const initialState = {
  Comments: {
    page: 0,
    results: [],
    totalPages: 1,
  },
  errors: {},
  loadings: {},
  errorMessages: {},
  errorCodes: {},
  paramsForThunk: {},
  search: null,
  order: "asce",
  userIds: [],
};

const workspaceSlice = createSlice({
  name: "workspace",
  initialState,
  reducers: {
    setSearchValue(state, action) {
      state.search = action.payload;
    },
    setOrderValue(state, action) {
      state.order = action.payload;
    },
    storeUserIds: (state, action) => {
      state.userIds = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCommentsAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.page > 1) {
          state.Comments = {
            ...action.payload,
            Comments: state?.Comments?.results?.concat(action?.payload?.results),
          };
        } else {
          state.Comments = action.payload;
        }
      })
      // im using addMatcher to manage the asyncthunksMehtod actions like fullfilled,pending,rejected and also to manage the errors loading and error messages and async params
      .addMatcher(
        // isAsyncThunk will run when the action is an asyncthunk exists from giver asycntthunks
        isAnyOf(
          // reduxToolKitCaseBuilder helper make fullfilled, pending, and rejected cases
          ...reduxToolKitCaseBuilder([
            createCommentAsyncThunk,
            getCommentsAsyncThunk,
          ])
        ),
        handleLoadingErrorParamsForAsycThunk
      );
  },
});

export default workspaceSlice.reducer;
export const {
  setLoading,
  setSearchValue,
  setCategoryValue,
  setOrderValue,
  storeUserIds,
  setSelectedWorkspace,
} = workspaceSlice.actions;
