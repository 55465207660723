import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Featured from "../assets/images/icons/Featured.svg";

const DeleteModal = ({ show, handleClose, onConfirmDelete }) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      className="delete-modal-wrapper"
      centered
    >
      <Modal.Header>
        <div className="delete-image">
          <img src={Featured} alt="Feature Delete" />
        </div>
      </Modal.Header>
      <Modal.Body>
        <p style={{ fontSize: "18px", fontWeight: "600" }}>Delete Item</p>
        <span style={{ fontSize: "14px", fontWeight: "#667085" }}>
          Are you sure you want to delete this item?
        </span>
      </Modal.Body>
      <Modal.Footer style={{ display: "flex" }}>
        <div className="btns" style={{ display: "flex", gap: "12px" }}>
          <Button
            variant="secondary"
            onClick={handleClose}
            style={{
              fontSize: "16px",
              fontWeight: "600",
              color: "black",
              backgroundColor: "white",
              border: "1px solid #D0D5DD",
            }}
          >
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              onConfirmDelete(); 
              handleClose();
            }}      
                  style={{
              fontSize: "16px",
              fontWeight: "600",
              color: "red",
              backgroundColor: "white",
              border: "1px solid red",
            }}
          >
            Delete
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteModal;
