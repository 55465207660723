import React, { useState } from "react";
import { GrAttachment } from "react-icons/gr";
import { LuCircle } from "react-icons/lu";
import statusicon2 from "../assets/images/icons/statusicon2.svg";
import statusicon3 from "../assets/images/icons/statusicon3.svg";
import statusicon4 from "../assets/images/icons/statusicon4.svg";
import DeleteModal from "./DeleteModal";
import radiobtn from "../assets/images/icons/radiobtn.svg";
import NodataFound from "./NodataFound";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loadingdata from "../pages/loadingdata";
import low from "../assets/images/icons/low.png";
import urgent from "../assets/images/icons/svg9.svg";
import medium from "../assets/images/icons/svg7.svg";
import high from "../assets/images/icons/svg8.svg";
import { Dropdown } from "react-bootstrap";
import { editTaskAsyncThunk } from "../redux/pagesSlices/taskSlice";
import todo from "../assets/images/icons/status1.svg";
import progress from "../assets/images/icons/progress.svg";
import done from "../assets/images/icons/statusicon4.svg";
import due from "../assets/images/icons/x-Vector.svg"
import status from "../assets/images/icons/Frame (1).png";
// Correctly defining statusIcon as an object
const statusIcon = {
  Todo: <LuCircle />, // React icon
  "In progress": <img src={statusicon2} alt="In progress" />, // Custom SVG icon
  Backlog: <img src={statusicon3} alt="Backlog" />, // Custom SVG icon
  Done: <img src={statusicon4} alt="Done" />, // Custom SVG icon
};

const priorityIcon = {
  low: <img src={low} alt="Low" />, // React icon
  medium: <img src={medium} alt="Medium" />, // Custom SVG icon
  high: <img src={high} alt="High" />, // Custom SVG icon
  urgent: <img src={urgent} alt="Urgent" />, // Custom SVG icon
};
const TasksTable = ({ headerIcon, headerTitle, assignTasks }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const navigate = useNavigate();
  const d = useDispatch();
  const loading = useSelector(
    (state) => state.user.loadings?.getAssignTaskAsyncThunk
  );

  const handleUpdate = (id, key, value, projectId) => {
    d(
      editTaskAsyncThunk({
        id,
        data: {
          [key]: value,
        },
        from: "assign",
        callBack: () => {
          // getData();
        },
      })
    );
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  return (
    <div className="task-table-container">
      {assignTasks?.length > 0 ? (
        assignTasks?.map((assignTask) => (
          <table className="task-table">
            <thead>
              <tr style={{ backgroundColor: "#F0F0F0" }}>
                <th
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <img src={headerIcon} alt="" />
                  <span style={{ fontSize: "13px", fontWeight: "500" }}>
                    {assignTask?.name || "Classroom"}
                  </span>
                </th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <thead>
              <tr>
                <th>Tasks</th>
                <th>Assignee</th>
                <th>Due Date</th>
                <th>Status</th>
                <th>Priority</th>
                <th>Attachment</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <Loadingdata />
              ) : (
                <>
                  {assignTask?.Task?.map((task, idx) => (
                    <tr key={idx}>
                      <td>
                        <div className="icon-wrapper">
                          <img src={radiobtn} alt="" />
                          <p>{task.name}</p>
                        </div>
                      </td>
                      <td className="assignee">
                        {task.assignees && task.assignees.length > 0 && (
                          <img
                            src={task.assignees[0]?.user?.picture}
                            alt="Assignee"
                          />
                        )}
                      </td>
                      <td
                        style={{
                          color:
                            task.dueDate === "10-Sep" ? "#0FC27B" : "inherit",
                        }}
                      >
                        {" "}
                        {/* Color change for due date */}
                        {formatDate(task.dueDate)}
                      </td>
                      <td>
                        <Dropdown
                          className="dropdown-wraper"
                          onSelect={(key) =>
                            handleUpdate(task.id, "status", key)
                          }
                        >
                          <Dropdown.Toggle className="status-icon">
                            {statusIcon[task.status]}
                            <p className="pb-0">
                              {task.status === "in_progress"
                                ? "In Progress"
                                : task.status}
                            </p>
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="dropdown-menu-right">
                            <Dropdown.Item eventKey={"todo"}>
                              <div className="status-icon">
                                {statusIcon["todo"]}
                                <img src={todo} alt="status" />
                                <p>todo</p>
                              </div>
                            </Dropdown.Item>
                            <Dropdown.Item eventKey={"in_progress"}>
                              <div className="status-icon">
                                {statusIcon["in_progress"]}
                                <img src={progress} alt="progresss" />
                                <p>In progress</p>
                              </div>
                            </Dropdown.Item>

                            <Dropdown.Item eventKey={"backlog"}>
                              <div className="status-icon">
                                {statusIcon["backlog"]}
                                <img src={status} alt="progresss" />
                                <p>Backlog</p>
                              </div>
                            </Dropdown.Item>
                            <Dropdown.Item eventKey={"done"}>
                              <div className="status-icon">
                                {statusIcon["done"]}
                                <img src={done} alt="done" />
                                <p>done</p>
                              </div>
                            </Dropdown.Item>
                            <Dropdown.Item eventKey={"due"}>
                              <div className="status-icon">
                                {statusIcon["due"]}
                                <img src={due} alt="due" />
                                <p>Due</p>
                              </div>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                      <td>
                        <Dropdown
                          className="dropdown-wraper"
                          onSelect={(key) => handleUpdate(task.id, "priority", key)}
                        >
                          <Dropdown.Toggle className="status-icon">
                            {priorityIcon[task.priority]}
                            <p className="pb-0">{task.priority}</p>
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="dropdown-menu-right">
                            <Dropdown.Item eventKey={"low"}>
                              <div className="status-icon">
                                {priorityIcon["low"]}
                                <p>Low</p>
                              </div>
                            </Dropdown.Item>
                            <Dropdown.Item eventKey={"medium"}>
                              <div className="status-icon">
                                {priorityIcon["medium"]}
                                <p>Medium</p>
                              </div>
                            </Dropdown.Item>

                            <Dropdown.Item eventKey={"high"}>
                              <div className="status-icon">
                                {priorityIcon["high"]}
                                <p>High</p>
                              </div>
                            </Dropdown.Item>
                            <Dropdown.Item eventKey={"urgent"}>
                              <div className="status-icon">
                                {priorityIcon["urgent"]}
                                <p>Urgent</p>
                              </div>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                      <td>
                        <div className="icon-wrapper">
                          <GrAttachment />
                          <p>{task.attachment}</p>
                        </div>
                        <div className="icon-wrapper">
                          {/* <Dropdown>
                    <Dropdown.Toggle
                      style={{
                        background: "transparent",
                        border: "none",
                        color: "black",
                      }}
                      id="dropdown-basic"
                      className="custom-dropdown-toggle"
                    >
                      <img src={threedot} alt="threedot" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="delete-button">
                      <Dropdown.Item href="" >
                        <img src={del} alt="delete" />
                        <span>Delete </span>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown> */}
                        </div>
                      </td>
                      {/* <td>
                
              </td> */}
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
        ))
      ) : (
        <NodataFound
          title={"Task"}
          navigateHandler={() => {
            navigate("/projects");
          }}
        />
      )}

      <DeleteModal show={show} handleClose={handleClose} />
    </div>
  );
};

export default TasksTable;
