import React from "react";
import CreateTaskModal from "../creatTaskModel";
import CreatDocModel from "../creatDocModel";
import AssinessModel from "../AssinessModel";
import UnAssignModel from "../UnAssignModel";
import CreateNoteModel from "../createNoteModel";
import CreateProjectModal from "../CreateProjectModal";

export default function Index() {
  return (
    <>
      <CreateTaskModal />
      <CreatDocModel />
      <AssinessModel />
      <CreateNoteModel />
      <UnAssignModel />
      <CreateProjectModal />
    </>
  );
}
