import React, { useEffect, useState } from "react";
import google from "../assets/images/icons/google.png";
import SignUpFooter from "../components/SignUpFooter";
import { HiCheckCircle } from "react-icons/hi";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  createUserAsyncThunk,
} from "../redux/pagesSlices/userSlice";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { useSignIn, useSignUp } from "@clerk/clerk-react";
import emailIcon from "../assets/images/icons/email-icon.svg";
import passwordIcon from "../assets/images/icons/password-icon.svg";
import LogoBar from "../components/LogoTopBar";

// Define Yup validation schema
const SignUpSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters long")
    .matches(
      /[!@#$%^&*(),.?":{}|<>]/,
      "Password must contain at least one special character"
    )
    .required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm password is required"),
});

function SignUp() {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const invitedemail = searchParams.get("email");
  const { signUp, setActive } = useSignUp();
  const { signIn } = useSignIn();
  const { loadings } = useSelector((state) => state.user);
  const navigate = useNavigate();

  // States for password validation
  const [isMinLength] = useState(false);
  const [hasSpecialChar] = useState(false);
;

  const createAccount = (values) => {
    console.log("values11", values);
    dispatch(
      createUserAsyncThunk({
        data: values,
        signUp,
        setActive,
        navigate,
        callBack: () => {
          toast.success("Account created successfully");
          navigate("/sign-in");
        },
      })
    );
  };

  const handleContinueWithGoogle = async () => {
    // dispatch(
    //   signInWithGoogleAsyncThunk({
    //     signUp,
    //     userId,
    //     signIn,
    //     callBack: async () => {
    //       navigate("/");
    //     },
    //   })
    // );
    handleSignIn("oauth_google");
  };
  async function handleSignIn(strategy) {
    if (!signIn || !signUp) return null;

    // If the user has an account in your application, but does not yet
    // have an OAuth account connected to it, you can transfer the OAuth
    // account to the existing user account.
    const userExistsButNeedsToSignIn =
      signUp.verifications.externalAccount.status === "transferable" &&
      signUp.verifications.externalAccount.error?.code ===
        "external_account_exists";

    if (userExistsButNeedsToSignIn) {
      const res = await signIn.create({ transfer: true });

      if (res.status === "complete") {
        setActive({
          session: res.createdSessionId,
        });
      }
    }

    // If the user has an OAuth account but does not yet
    // have an account in your app, you can create an account
    // for them using the OAuth information.
    const userNeedsToBeCreated =
      signIn.firstFactorVerification.status === "transferable";

    if (userNeedsToBeCreated) {
      const res = await signUp.create({
        transfer: true,
      });

      if (res.status === "complete") {
        setActive({
          session: res.createdSessionId,
        });
      }
    } else {
      // If the user has an account in your application
      // and has an OAuth account connected to it, you can sign them in.
      signInWith(strategy);
    }
  }

  const signInWith = (strategy) => {
    return signIn.authenticateWithRedirect({
      strategy,
      redirectUrl: "/oauth-callback",
      redirectUrlComplete: "/",
    });
  };

  useEffect(() => {
    if (!invitedemail) {
      localStorage.removeItem("__clerk_ticket");
      localStorage.removeItem("__clerk_status");
      localStorage.removeItem("invite-workspaceName");
      localStorage.removeItem("invite-invitedBy");
      localStorage.removeItem("invite-email");
    }
  }, [invitedemail]);
  return (
    <div className="home-page">
      <LogoBar />
      <div className="container mt-5">
        <div className="signup-wrapper-form">
          <div className="row">
            <div className="col-md-6">
              <div className="signup-form-outer">
                <div className="google-wrapper-main">
                  <div
                    className="google-wrapper"
                    style={{ cursor: "pointer" }}
                    onClick={handleContinueWithGoogle}
                  >
                    <img src={google} alt="google" />
                    <strong>Sign Up with Google</strong>
                  </div>
                </div>
                <div className="form-wrapper">
                  <Formik
                    initialValues={{
                      // firstName: '',
                      // lastName: '',
                      email: invitedemail|| '',
                      // phone: '',
                      password: "",
                      confirmPassword: "",
                    }}
                    enableReinitialize
                    validationSchema={SignUpSchema}
                    onSubmit={(values) => {
                      createAccount(values);
                    }}
                  >
                    {({ errors, touched, values }) => (
                      <Form className="form authentication-form">
                        <div className="form-group">
                          <Field
                            type="text"
                            name="email"
                            value={invitedemail || values.email}
                            className={`form-control ${
                              errors.email && touched.email ? "is-invalid" : ""
                            }`}
                            placeholder="Enter your email address"
                          />
                          <img src={emailIcon} alt="icon" />
                        </div>
                        <ErrorMessage
                          component="div"
                          name="email"
                          className="validation-message"
                        />
                        <div className="form-group">
                          <Field
                            type="password"
                            name="password"
                            className={`form-control ${
                              errors.password && touched.password
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder="Confirm Password"
                          />
                          <img src={passwordIcon} alt="icon" />
                        </div>
                        <ErrorMessage
                          component="div"
                          name="password"
                          className="validation-message"
                        />

                        <div className="form-group">
                          <Field
                            type="password"
                            name="confirmPassword"
                            className={`form-control ${
                              errors.confirmPassword && touched.confirmPassword
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder="Confirm Password"
                          />
                          <img src={passwordIcon} alt="icon" />
                        </div>
                        <ErrorMessage
                          component="div"
                          name="confirmPassword"
                          className="validation-message"
                        />

                        <div className="character-wrapper">
                          {touched.password && values.password.length >= 8 ? (
                             <div className="active">
                             <HiCheckCircle />
                             </div>
                          ) : (
                            <HiCheckCircle />
                          )}
                          <p>
                            {hasSpecialChar
                              ? "Password contains a special character"
                              : "Must contain 1 special character"}
                          </p>
                        </div>

                        <div className="character-wrapper">
                          {touched.confirmPassword &&
                          values.confirmPassword.length >= 8 ? (
                            <div className="active">
                            <HiCheckCircle />
                            </div>
                          ) : (
                            <HiCheckCircle />
                          )}
                          <p>
                            {isMinLength
                              ? "Password has at least 8 characters"
                              : "Must be at least 8 characters"}
                          </p>
                        </div>

                        <button
                          type="submit"
                          className="btn-style full"
                          disabled={loadings.createUserAsyncThunk}
                        >
                          {loadings.createUserAsyncThunk ? (
                            <Spinner
                              animation="border"
                              role="status"
                              size="sm"
                            />
                          ) : (
                            "Create Account"
                          )}
                        </button>

                        <div className="content-wrapper">
                          <p>
                            Already have an account?{" "}
                            <Link to="/sign-in">Log In</Link>
                          </p>
                        </div>
                      </Form>
                    )}
                  </Formik>

                  <div className="form-content-wrapper">
                    <p>
                      By inserting your email you confirm you agree to Complya
                      contacting you about our product and services. You can opt
                      out at any time by clicking unsubscribe in our Reports.
                      Find out more about how we use data in our <span><Link to="#">privacy policy.</Link></span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="welcome-wrapper-main">
                <h6>Welcome to Complya.</h6>
                <p>
                  Complya is a radically new type of CRM. Built on an entirely
                  new type of data architecture, you'll have proDocs and records
                  of every interaction within your network in minutes, always
                  updated in real-time.
                </p>
                <p className="normal-weight-text">
                  You'll be able to customize and create your CRM exactly as you
                  want it.
                </p>
                <p className="normal-weight-text">Let's begin.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SignUpFooter />
    </div>
  );
}

export default SignUp;
