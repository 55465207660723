import React, { useEffect, useState } from "react";
import img2 from "../assets/images/icons/img2.png";
import img3 from "../assets/images/icons/img3.png";
import img4 from "../assets/images/icons/Frame (1).png";
import img5 from "../assets/images/icons/img5.png";
import img6 from "../assets/images/icons/img6.png";
import img7 from "../assets/images/icons/img7.png";
import img11 from "../assets/images/icons/logo-circular.png";
import img12 from "../assets/images/icons/img12.png";
import img13 from "../assets/images/icons/img13.png";
import img14 from "../assets/images/icons/img14.png";
import img15 from "../assets/images/icons/img15.png";
import img16 from "../assets/images/icons/img16.png";
import Topbar from "../components/Topbar";
import NoNotes from "../components/NoNotes";
import ProjectTask from "../components/ProjectTask";
import ProjectDocuments from "../components/ProjectDocuments";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { getProjectAsyncThunk } from "../redux/pagesSlices/projectSlice";
import Loadingdata from "./loadingdata";
import status from "../assets/images/icons/Frame (1).png";
import low from "../assets/images/icons/low.png";
import urgent from "../assets/images/icons/svg9.svg";
import progress from "../assets/images/icons/progress.svg";
import done from "../assets/images/icons/statusicon4.svg";
import medium from "../assets/images/icons/svg7.svg";
import high from "../assets/images/icons/svg8.svg";
import {
  createCommentAsyncThunk,
} from "../redux/pagesSlices/commentSlice";
import moment from "moment/moment";
import classicon from "../assets/images/icons/class-room-icon.svg";

import todo from "../assets/images/icons/status1.svg";
import { Dropdown } from "react-bootstrap";
import { BsThreeDots } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import { deleteNoteAsyncThunk, getNotesAsyncThunk } from "../redux/pagesSlices/noteSlice";
import { handleModel } from "../redux/layoutSlices/modelSlice";
function ProjectDetail() {
  const d = useDispatch();
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const [activeTab, setActiveTab] = useState(tab || "Details");
  const [commnetValue, setCommentValue] = useState("");
  const [error, setError] = useState(null);
  const SingleProject = useSelector((state) => state?.project?.project);
  const notes = useSelector((state) => state?.notes?.Notes);
  console.log("notes", notes);
  const loaging = useSelector(
    (state) => state?.project?.loadings?.getProjectAsyncThunk
  );
  const [_, setCreateProjectModalShow] = useState(false);
  const getData = () => {
    d(getNotesAsyncThunk({ id, params: { page: 1, limit: 1000 } }));
  };
  useEffect(() => {
    if (id) {
      d(getProjectAsyncThunk({ id }));
      d(getNotesAsyncThunk({ id, params: { page: 1, limit: 1000 } }));
    }
  }, [id, d]);

  useEffect(() => {
    if (tab) {
      setActiveTab(tab);
    }
  }, [tab]);

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  const handleTabChange = (tabName) => {
    console.log(tabName);
    setSearchParams({ tab: tabName });
  };

  const handleCreateProjectShow = () => setCreateProjectModalShow(true);

  const handlePostComment = async () => {
    if (!commnetValue.trim()) {
      setError("Please write something");
      return;
    }

    setError("");
    d(
      createCommentAsyncThunk({
        data: { content: commnetValue, projectId: id },
      })
    );
    setCommentValue("");
  };

  const deleteNote = (noteId) => {
    d(
      deleteNoteAsyncThunk({
        id: noteId,
        callBack: () => {
          getData();
          d(getProjectAsyncThunk({ id }));
        },
      })
    );
  };

  return (
    <div className="projects-detail-page">
      <Topbar
        activeTab={activeTab}
        handleCreateProjectShow={handleCreateProjectShow}
      />

      <div className="container-fluid">
        {/* Tabs Navigation */}
        <div className="tabs-container">
          <div
            className={`tab-item ${activeTab === "Details" ? "active" : ""}`}
            onClick={() => handleTabChange("Details")}
          >
            <img src={img2} alt="Details Icon" />
            Details
          </div>
          <div
            className={`tab-item ${activeTab === "Notes" ? "active" : ""}`}
            onClick={() => handleTabChange("Notes")}
          >
            <img src={img13} alt="Notes Icon" />
            Notes{" "}
            {SingleProject?._count?.projectNotes ? (
              <span className="badge">
                {SingleProject?._count?.projectNotes}
              </span>
            ) : (
              ""
            )}
          </div>
          <div
            className={`tab-item ${activeTab === "Tasks" ? "active" : ""}`}
            onClick={() => handleTabChange("Tasks")}
          >
            <img src={img14} alt="Tasks Icon" />
            Tasks{" "}
            {SingleProject?._count?.Task ? (
              <span className="badge">{SingleProject?._count?.Task}</span>
            ) : (
              ""
            )}
          </div>
          <div
            className={`tab-item doc-item ${
              activeTab === "Docs" ? "active" : ""
            }`}
            onClick={() => handleTabChange("Docs")}
          >
            <img src={img15} alt="Docs Icon" />
            Docs{" "}
            {SingleProject?._count?.ProjectAttachments ? (
              <span className="badge">
                {SingleProject?._count?.ProjectAttachments}
              </span>
            ) : (
              ""
            )}
          </div>
        </div>

        {/* Content for Each Tab */}
        {loaging ? (
          <Loadingdata />
        ) : (
          <div className="tab-content pb-0">
            {activeTab === "Details" && (
              <>
                <div className="details-tab project-detail-tabs-wrapper">
                  <div className="top-heading">
                    <h1>Properties</h1>
                  </div>
                  <div className="content-div mt-4">
                    <div className="content">
                      <img className="coment-icon" src={img3} alt="Name Icon" />
                      <p>Name</p>
                    </div>
                    <h1 className="heading">{SingleProject?.name}</h1>
                  </div>
                  <div className="content-div">
                    <div className="content">
                      <img src={img4} alt="Status Icon" />
                      <p>Status</p>
                    </div>
                    <div>
                      <div className="progres">
                        {SingleProject.status === "todo" ? (
                          <img
                            className="status-icon"
                            src={todo}
                            alt="status"
                          />
                        ) : SingleProject.status === "in_progress" ? (
                          <img src={progress} alt="progresss" />
                        ) : SingleProject.status === "backlog" ? (
                          <img src={status} alt="progresss" />
                        ) : SingleProject.status === "done" ? (
                          <img src={done} alt="done" />
                        ) : (
                          <img
                            className="status-icon"
                            src={status}
                            alt="status"
                          />
                        )}
                        {/* <img src={img9} alt="Progress Icon" /> */}
                        <p>
                          {SingleProject?.status === "in_progress"
                            ? "In Progress"
                            : SingleProject.status}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="content-div">
                    <div className="content">
                      <img src={img5} alt="Priority Icon" />
                      <p>Priority</p>
                    </div>
                    <div>
                      <div className="progres">
                        {SingleProject.priority === "low" ? (
                          <img src={low} alt="done" />
                        ) : SingleProject.priority === "medium" ? (
                          <img src={medium} alt="done" />
                        ) : SingleProject.priority === "high" ? (
                          <img src={high} alt="done" />
                        ) : SingleProject.priority === "high" ? (
                          <img src={high} alt="done" />
                        ) : SingleProject.priority === "urgent" ? (
                          <img src={urgent} alt="done" />
                        ) : (
                          <img src={low} alt="done" />
                        )}
                        {/* <img src={img10} alt="Medium Priority" /> */}
                        <p>{SingleProject?.priority}</p>
                      </div>
                    </div>
                  </div>
                  <div className="content-div">
                    <div>
                      <div className="content">
                        <img src={img6} alt="Assignee Icon" />
                        <p>Assignee</p>
                      </div>
                    </div>
                    <div className="avatars-wrapper">
                      <div className="single-avatar">
                        {SingleProject.assignees &&
                          SingleProject.assignees.length > 0 && (
                            <>
                              {SingleProject.assignees
                                .slice(0, 3)
                                .map((assignee, idx) => (
                                  <img
                                    key={idx}
                                    src={assignee.user.picture}
                                    alt="Assignee"
                                    style={{
                                      width: "24px",
                                      height: "24px",
                                      borderRadius: "50%",
                                      marginRight: "5px",
                                    }}
                                  />
                                ))}
                              {SingleProject.assignees.length > 3 && (
                                <span className="avatar-count">
                                  <strong>
                                    +{SingleProject.assignees.length - 3}
                                  </strong>
                                </span>
                              )}
                            </>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="content-div">
                    <div className="content">
                      <img src={img7} alt="Due Date Icon" />
                      <p>Due Date</p>
                    </div>
                    <p>
                      <span className="date">
                        {formatDate(SingleProject?.dueDate)}
                      </span>
                    </p>
                  </div>
                  <div className="content-div">
                    {/* <div className="content"> */}
                    {/* <img src={img8} alt="Attachment Icon" /> */}
                    {/* <p>Attached</p> */}
                    {/* </div> */}
                    {SingleProject?.attachments?.map((item) => (
                      <p className="complya">{item || "Compya@.com"}</p>
                    ))}
                  </div>
                </div>
                <div className="bootom mt-5 detail_bootom"></div>
                <div>
                  <h3 className="comments">
                    <img src={img16} alt="Comments Icon" /> Comments{" "}
                    <span className="notes">{SingleProject?.Comments?.length || 0}</span>
                  </h3>
                </div>
                <div>
                  {SingleProject?.Comments?.map((item, index) => {
                    return (
                      <div className="com-main">
                        <div className="comment-sec">
                          <img
                            className="com-img"
                            src={img11}
                            alt="User Avatar"
                          />
                          <h1 className="com-head">
                            {item?.user?.firstName}{" "}
                            {item?.user?.lastName || " "}
                            <span className="com-p">
                              {" "}
                              {moment(item?.createdAt).from(Date.now())}
                            </span>
                          </h1>
                        </div>
                        <h1 className="this-com">{item?.content}</h1>
                      </div>
                    );
                  })}
                </div>
                <div className="com-main">
                  <div className="comment-sec">
                    <img className="com-img mt-1" src={img11} alt="User Avatar" />
                    {/* <h5 className="com-head2">Add comment.... </h5> */}

                    <form onSubmit={(e) => e.preventDefault()} className="flex-grow-1">
                      <textarea
                        onChange={(e) => {
                          setCommentValue(e.target.value);
                          if (error) setError(""); 
                        }}
                        value={commnetValue}
                        className="com-head2"
                        // type="text"
                        placeholder="Add comment...."
                       rows="3"
                      />
                    </form>
                  </div>
                    {error && (
                      <p style={{ color: "red", fontSize: "12px" }}>{error}</p>
                    )}
                  <div className="but-main">
                    {/* <img className="emogi" src={img12} alt="Emoji" /> */}
                    <button
                      onClick={() => handlePostComment()}
                      className="sign-in"
                      // disabled={!commnetValue.trim()}
                    >
                      Comment
                    </button>
                  </div>
                </div>
              </>
            )}

            {activeTab === "Notes" && (
              <div>
                {notes?.results?.length === 0 ? (
                  <div className="notes-tab">
                    <NoNotes />
                  </div>
                ) : (
                  <div class="container-fluid">
                    <div
                      class="task-table-container"
                    >
                      <table class="task-table">
                        <thead>
                          <tr>
                            <th>Title</th>
                            <th>Content</th>
                            <th>User</th>
                            <th>Created At</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {notes?.results?.map((item, key) => (
                            <tr key={key}>
                              <td>
                                <div className="icon-wrapper">
                                  <img
                                    src={item?.icon || classicon}
                                    alt="classicon"
                                  />
                                  <p>{item?.title}</p>
                                </div>
                              </td>
                              <td>
                                <div className="status-icon">
                                  <p title={item.content}>{item.content}</p>
                                </div>
                              </td>
                              <td>
                                <div className="status-icon">
                                  <p>
                                    {item.user?.firstName} {item.user?.lastName}
                                  </p>
                                </div>
                              </td>
                              <td>
                                <div className="status-icon">
                                  <p>
                                    {formatDate(item?.createdAt)}
                                  </p>
                                </div>
                              </td>
                              <td>
                                <Dropdown className="dropdown-wraper">
                                  <Dropdown.Toggle
                                    style={{
                                      background: "transparent",
                                      border: "none",
                                      color: "black",
                                    }}
                                  >
                                    <BsThreeDots />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item eventKey="Todo" onClick={() => d(handleModel({args:{editId: item?.id, id: item.projectId, formData: item}, model: "CreateNoteModal", state: true}))}>
                                      <FiEdit />
                                      <span>Edit</span>
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="Todo"
                                      className="delete"
                                      onClick={() => deleteNote(item?.id)}
                                    >
                                      <MdDelete />
                                      <span className="delete">Delete</span>
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </div>
            )}

            {activeTab === "Tasks" && (
              <div className="tasks-tab">
                <ProjectTask />
              </div>
            )}

            {activeTab === "Docs" && (
              <div className="docs-tab">
                <ProjectDocuments />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default ProjectDetail;
