import React from 'react'
import SignUpFooter from '../components/SignUpFooter'
import checkCicle from "../assets/images/icons/check-circle.svg"
import LogoBar from '../components/LogoTopBar';
import { Link } from 'react-router-dom';

function EmailVarified() {
  return (
    <div className='home-page'>
      <LogoBar />
    <div className="container mt-5">
       <div className='check-email-wrapper'>
           <div className='key-icon-wrapper'>
               <div className='box'>
               <img src={checkCicle} alt="icon" />
               </div>
               <h6>Email Verified!</h6>
               <p>We sent a verification link to your email</p>
               <Link to={'/login'} className='btn-style full'>Log in</Link>
           </div>
       </div>
    </div>
    <SignUpFooter/>
   </div>
  )
}

export default EmailVarified