import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import { ApiRequests } from "../../service/ApiRequests";
import {
  catchAsync,
  handleLoadingErrorParamsForAsycThunk,
  reduxToolKitCaseBuilder,
} from "../../helpers/detectError";
import { toast } from "react-toastify";

export const createWorkspaceAsyncThunk = createAsyncThunk(
  "workspace/createWorkspaceAsyncThunk",
  catchAsync(async ({ data, callBack }, { dispatch, getState }) => {
    const response = await ApiRequests.createWorkSpace(data);

    if (response.status === 201) {
      toast.success("Invite Sent");
      localStorage.setItem("workspace", JSON.stringify(response?.data));
    }
    if (callBack) callBack();
    return response?.data;
  })
);

export const sendInvitationAsyncThunk = createAsyncThunk(
  "workspace/sendInvitationAsyncThunk",
  catchAsync(async ({ data, callBack }) => {
    const response = await ApiRequests.inviteMember(data);
    if (response.status === 201) {
      toast.success("Invite Sent");
    }
    if (callBack) callBack();
    return response?.data;
  })
);

export const rejectInvitationAsyncThunk = createAsyncThunk(
  "workspace/rejectInvitationAsyncThunk",
  catchAsync(async ({ data, callBack }) => {
    const response = await ApiRequests.rejectInvitation(data);
    if (callBack) callBack();
    return response?.data;
  })
);

///////////////////////////////////////////////////

const initialState = {
  //news states
  workspaces: {
    page: 0,
    users: [],
    totalPages: 1,
  },
  workspace: null,
  workspacesCount: {
    page: 0,
    results: [],
    totalPages: 1,
  },
  listings: {
    page: 0,
    results: [],
    totalPages: 1,
  },
  errors: {},
  loadings: {},
  errorMessages: {},
  errorCodes: {},
  paramsForThunk: {},
  search: null,
  categoryId: null,
  categories: [],
  order: "asce",
  userIds: [],
};

const workspaceSlice = createSlice({
  name: "workspace",
  initialState,
  reducers: {
    setSearchValue(state, action) {
      state.search = action.payload;
    },
    setCategoryValue(state, action) {
      state.categoryId = action.payload;
    },
    setOrderValue(state, action) {
      state.order = action.payload;
    },
    storeUserIds: (state, action) => {
      state.userIds = action.payload;
    },
    setSelectedWorkspace(state, action) {
      state.workspace = action.payload;
      localStorage.setItem("workspace", JSON.stringify(action.payload));
    },
  },
  extraReducers: (builder) => {
    builder
      // im using addMatcher to manage the asyncthunksMehtod actions like fullfilled,pending,rejected and also to manage the errors loading and error messages and async params
      .addMatcher(
        // isAsyncThunk will run when the action is an asyncthunk exists from giver asycntthunks
        isAnyOf(
          // reduxToolKitCaseBuilder helper make fullfilled, pending, and rejected cases
          ...reduxToolKitCaseBuilder([
            createWorkspaceAsyncThunk,
            sendInvitationAsyncThunk,
          ])
        ),
        handleLoadingErrorParamsForAsycThunk
      );
  },
});

export default workspaceSlice.reducer;
export const {
  setLoading,
  setSearchValue,
  setCategoryValue,
  setOrderValue,
  storeUserIds,
  setSelectedWorkspace,
} = workspaceSlice.actions;
