import React from 'react'
import { Link } from 'react-router-dom'

function SignUpFooter() {
  return (
    <div className='sign-up-footer-wrapper'>
        <div className="footer-menu">
            <ul>
                  <li>© 2024 Complya Limited</li>
                <li><Link href="#">Privacy Policy</Link></li>
                <li><Link href="#">Support</Link></li>
            </ul>
        </div>
    </div>
  )
}

export default SignUpFooter