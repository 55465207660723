const localhost = process.env.REACT_APP_NODE_ENV === "localhost";
const development = process.env.REACT_APP_NODE_ENV === "development";
export const app_mode = localhost
  ? "localhost"
  : development
    ? "development"
    : "production";

export const apiBasePath =
  process.env.REACT_APP_API_URL ??
  (localhost
    ? "http://localhost:3333/api/"
    : development
      ? "http://192.168.18.66:3333/api/"
      : "https://api.complya.dev/api/"
  );

export const APIurls = {
  // auth
  login: "auth/login",
  refreshTokens: "auth/refresh-tokens",
  register: "auth/register",
  logout: "auth/logout",
  authenticate: "auth/authenticate",

  users: "users",
  userMetadata: "users/user-metadata",
  workSpaces: "workspaces",
  invite: "invites",
  loads: "load",
  loadStops: "loadstop",
  fleets: "fleet",
  project:"projects",
  workspaceProject:"projects/workspace",
  documents:"documents",  
  projectdocuments:"documents/project",  
  assignDocuments:"documents/user",
  workSpacesCount: "users/workspace/users",
  task:"tasks",
  projectTask:"tasks/project",
  assignTasks:"tasks/user",
  comments:"comments",
  notes:"notes"
};




export const fileTypes = [
  // "GIF",
  "MP4",
  "WEBM",
  "AVI",
  "MKV",
  "MOV",
  "FLV",
];
export const imageFileTypes = [
  "JPEG",
  "JPG",
  "PNG",
  "GIF",
  "BMP",
  "TIFF",
];