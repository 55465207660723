import React from "react";
import { FaRegFile } from "react-icons/fa";
import doc from "../../src/assets/images/doc.png";
import { useParams, useLocation } from "react-router-dom";
import { handleModel } from "../redux/layoutSlices/modelSlice";
import { useDispatch } from "react-redux";

export default function NodataFound({ title, navigateHandler }) {
  const { id } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();

  // Retrieve 'tab' parameter from the query string
  const searchParams = new URLSearchParams(location.search);
  const tab = searchParams.get("tab");

  const tabConfig = {
    Tasks: { model: "CreateTaskModal", text: `Create ${title}` },
    Docs: { model: "creatDocModel", text: `Create ${title}` },
  };

  const handleButtonClick = () => {
    const config = tabConfig[tab];
    if (config) {
      dispatch(
        handleModel({
          model: config.model,
          state: true,
          args: { id },
        })
      );
    }
  };

  return (
    <div className="no-project-found m-0 h-100 align-items-center">
      <div className="image-wrapper">
        <img src={doc} alt="project" />
        <div className="project-content">
          <h6>No {title}</h6>
          <p>
            There aren't currently any {title} here.
            <br />
            Create a {title} to keep track of important
            <br />
            information.
          </p>
          {tabConfig[tab] && (
            <div className="project-icon" style={{ cursor: "pointer" }}>
              <div className="create-sec" onClick={handleButtonClick}>
                <button className="btn-style">
                  <FaRegFile /> {tabConfig[tab].text}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
