import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import { ApiRequests } from "../../service/ApiRequests";
import {
  catchAsync,
  detectError,
  handleLoadingErrorParamsForAsycThunk,
  reduxToolKitCaseBuilder,
} from "../../helpers/detectError";
import { toast } from "react-toastify";
// import { useNavigate } from "react-router-dom";
// import { red } from "@mui/material/colors";

// Start User Slices
///////////////////////////////////////////////////

export const getUsersAsyncThunk = createAsyncThunk(
  "user/getUsersAsyncThunk",
  catchAsync(async (params, _) => {
    const response = await ApiRequests.getUsers(params);
    return response?.data;
  })
);

export const getUsersAsyncThunkNew = createAsyncThunk(
  "user/getUsersAsyncThunkNew",
  async (options, { dispatch, getState }) => {
    const { role, limit } = options;
    let allUserIds = [];

    let page = 0;
    let totalUsers = 0;

    do {
      const response = await ApiRequests.getOwners({ role, limit, page }); // Adjust this according to your API
      const { users, total } = response.data;
      allUserIds = [...allUserIds, ...users.map((user) => user.user_id)];
      totalUsers = total;
      page++;

      // Dispatch a Redux action to store the accumulated user IDs
      dispatch(storeUserIds(allUserIds));
    } while (allUserIds.length < totalUsers);

    return allUserIds;
  }
);

export const membersCheckUsernameAsyncThunk = createAsyncThunk(
  "user/membersCheckUsernameAsyncThunk",
  catchAsync(async ({ params, callBack }, { dispatch }) => {
    const response = await ApiRequests.membersCheckUsername(params);
    if (callBack) callBack(response?.data);
    return response?.data;
  })
);

export const filterClassBaseStudentsAsyncThunk = createAsyncThunk(
  "user/filterClassBaseStudentsAsyncThunk",
  catchAsync(async ({ params, callBack }, { dispatch }) => {
    const response = await ApiRequests.filterClassBaseStudents(params);
    if (callBack) callBack(response?.data);
    return response?.data;
  })
);

export const getUsersByIdsAsyncThunk = createAsyncThunk(
  "user/getUsersByIdsAsyncThunk",
  catchAsync(async (params, _) => {
    const response = await ApiRequests.getOwner(params);
    return response?.data;
  })
);

export const getUserAsyncThunk = createAsyncThunk(
  "user/getUserAsyncThunk",
  catchAsync(async (id, _) => {
    const response = await ApiRequests.getUser(id);
    return response?.data;
  })
);

export const createUserAsyncThunk = createAsyncThunk(
  "user/createUserAsyncThunk",
  catchAsync(async ({ data, signUp, setActive, navigate, callBack }) => {
    try {
      let token = localStorage.getItem("__clerk_ticket");
      const signupdata = {
        emailAddress: data.email,
        password: data.password,
      };
      if (token) {
        signupdata.strategy = "ticket";
        signupdata.ticket = token;
      }
      const signUpAttempt = await signUp.create(signupdata);

      if (signUpAttempt.status === "complete") {
        toast.success("Sign-up successful!");
        localStorage.setItem("user-id", signUpAttempt.id);
        await setActive({ session: signUpAttempt.createdSessionId });
        navigate(`/onboarding`);
      } else if (signUpAttempt.status === "missing_requirements") {
        if (signUpAttempt.unverifiedFields.includes("email_address")) {
          await signUpAttempt.prepareEmailAddressVerification({
            strategy: "email_link",
            redirectUrl: `${process.env.REACT_APP_FRONTEND_URL}/verified`,
          });
          navigate(`/new-verify/${data.email}`);
        }
      } else {
        toast.error("An unknown error occurred. Please try again.");
      }
    } catch (error) {
      console.error(error.errors);
      const message =
        error.message || error.errors.map((e) => e.message).join(", ");
      toast.error(message);
      throw message;
    }
  })
);

export const signInUserAsyncThunk = createAsyncThunk(
  "user/signInUserAsyncThunk",
  catchAsync(async ({ data, signIn, callBack }) => {
    try {
      const signInAttempt = await signIn.create({
        identifier: data.email,
        password: data.password,
      });
      if (signInAttempt.status === "complete") {
        toast.success("Sign-in successful!");
        localStorage.setItem("user-id", signInAttempt.id);
      }

      if (callBack) callBack();

      // return response?.data;
    } catch (error) {
      if (error.message) {
        toast.error(error.message);
        throw error.message;
      } else if (error.errors) {
        const messages = error.errors.map((error) => error.message).join(", ");
        toast.error(messages);

        throw messages;
      } else {
        toast.error("An unexpected error occurred. Please try again.");
      }
    }
  })
);

export const signInWithGoogleAsyncThunk = createAsyncThunk(
  "user/signInWithGoogleAsyncThunk",
  catchAsync(async ({ signUp, signIn, userId, callBack }) => {
    try {
      // Attempt to sign in the user
      await signIn.authenticateWithRedirect({
        strategy: "oauth_google",
        redirectUrl: `${process.env.REACT_APP_FRONTEND_URL}/`,
      });
      if (userId) {
        console.log("User signed in:", userId);
        // Handle signed in user
        if (callBack) callBack();
      } else {
        await signUp.authenticateWithRedirect({
          strategy: "oauth_google",
          redirectUrl: `${process.env.REACT_APP_FRONTEND_URL}/`,
        });

        if (callBack) callBack();
      }
    } catch (err) {
      console.error("Error during Google sign-in:", err);
      if (err.message) {
        toast.error(err.message);
      } else if (err.errors) {
        console.log(err.errors);
        const messages = err.errors.map((error) => error.message).join(", ");
        toast.error(messages);
      } else {
        toast.error("An unexpected error occurred. Please try again.");
      }
    }
  })
);

export const signInWithFacebookAsyncThunk = createAsyncThunk(
  "user/signInWithFacebookAsyncThunk",
  catchAsync(async ({ signUp, signIn, userId, callBack }) => {
    try {
      // Attempt to sign in the user
      await signIn.authenticateWithRedirect({
        strategy: "oauth_facebook",
        redirectUrl: `${process.env.REACT_APP_FRONTEND_URL}/load`,
      });

      if (userId) {
        console.log("User signed in:", userId);
        // Handle signed in user
        if (callBack) callBack();
      } else {
        await signUp.authenticateWithRedirect({
          strategy: "oauth_facebook",
          redirectUrl: `${process.env.REACT_APP_FRONTEND_URL}/load`,
        });

        console.log("New user created:");
        if (callBack) callBack();
      }
    } catch (err) {
      console.error("Error during Google sign-in:", err);
      if (err.message) {
        toast.error(err.message);
      } else if (err.errors) {
        console.log(err.errors);
        const messages = err.errors.map((error) => error.message).join(", ");
        toast.error(messages);
      } else {
        toast.error("An unexpected error occurred. Please try again.");
      }
    }
  })
);

export const updateUserAsyncThunk = createAsyncThunk(
  "user/updateUserAsyncThunk",
  catchAsync(async ({ id, data, callBack }, { dispatch, getState }) => {
    // console.log(state.users?.paramsForThunk)
    const response = await ApiRequests.updateUser({ id, data });
    if (response.status === 204) {
      toast.success("Student Updated Successfully!");
    }
    if (callBack) callBack();
    let params = {};
    let state1 = getState().listings;
    if (state1.search) params.name = state1.search;
    if (state1.order) params.sortBy = `name:${state1.order}`;
    dispatch(
      getUsersAsyncThunk({ ...params, populate: "user_id", role: "User" })
    );
    // dispatch(getUsersByIdsAsyncThunk({ populate: "image,user_id", ...state.users?.paramsForThunk?.getUsersByIdsAsyncThunk, page: 1 }))
    return response?.data;
  })
);

export const deleteUserAsyncThunk = createAsyncThunk(
  "user/deleteUserAsyncThunk",
  catchAsync(async (id, { dispatch, getState }) => {
    // const response = await ApiRequests.getAssets(filterparams);
    const response = await ApiRequests.deleteUser(id);
    toast.success("Student Deleted Successfully.");
    if (response.status === 204) {
      // toast.success("Student Deleted Successfully!");
      // let params = {};
      let state = getState().listings;
      // if (state.search) params.name = state.search;
      // if (state.order) params.sortBy = `name:${state.order}`;
      // dispatch(
      //   getUsersAsyncThunk({ ...params, populate: "user_id", role: "User" })
      // );

      let params = { role: "User", limit: 12, page: 0 };
      if (state.order) params.sortBy = `name:${state.order}`;
      dispatch(getUsersAsyncThunk({ ...params }));
    } else {
      toast.error(response.error);
    }
    return id;
  })
);

export const deleteMultipleUsersAsyncThunk = createAsyncThunk(
  "user/deleteMultipleUsersAsyncThunk",
  catchAsync(async ({ data, callBack }, { dispatch, getState }) => {
    // console.log("🚀 ~ file: userSlice.js:167 ~ catchAsync ~ data:", data);
    const response = await ApiRequests.deleteMultipleUsers(data);
    if (response.status === 204) {
      toast.success("Multiple Students Deleted Successfully!");
      let state = getState().listings;
      let params = { role: "User", limit: 12, page: 0 };
      if (state.order) params.sortBy = `name:${state.order}`;
      dispatch(getUsersAsyncThunk({ ...params }));
    } else {
      toast.error(response.error);
    }
    return response?.data;
  })
);

// export const createProjectAsyncThunk = createAsyncThunk(
//   "user/createProjectAsyncThunk",
//   catchAsync(async ({ data, callBack }, { dispatch, getState }) => {
//     const response = await ApiRequests.creatProject(data);
//     if (response.status === 201) {
//       toast.success("Project Created Successfully!");
//     }
//     if (callBack) await callBack();
//     return response?.data;
//   })
// );
// export const getProjectAsyncThunk = createAsyncThunk(
//   "user/getProjectAsyncThunk",
//   catchAsync(async ({ id, callBack }, { dispatch, getState }) => {
//     const response = await ApiRequests.getProject(id);
//     if (callBack) callBack(response?.data);
//     return response?.data;
//   })
// );
// export const editProjectAsyncThunk = createAsyncThunk(
//   "user/editProjectAsyncThunk",
//   catchAsync(async ({ id, data, callBack }, { dispatch, getState }) => {
//     const response = await ApiRequests.updateProject({ id, data });
//     // if (response.status === 200) {
//     // toast.success("Project Updated Successfully!");
//     // }
//     if (callBack) await callBack();

//     return response?.data;
//   })
// );

// export const getProjectsAsyncThunk = createAsyncThunk(
//   "user/getProjectsAsyncThunk",
//   catchAsync(async ({ params }, { dispatch, getState }) => {
//     const response = await ApiRequests.getProjectsListing(params);
//     return response?.data;
//   })
// );

// export const deleteProjectAsyncThunk = createAsyncThunk(
//   "user/deleteProjectAsyncThunk",
//   catchAsync(async ({ id, callBack }, { dispatch, getState }) => {
//     const response = await ApiRequests.deleteProject(id);
//     if (response.status === 204) {
//       toast.success("Project Deleted Successfully!");
//     }
//     if (callBack) await callBack();
//     return id;
//   })
// );

// export const assignProjectToUsersAsyncThunk = createAsyncThunk(
//   "project/assignProjectToUsersAsyncThunk",
//   catchAsync(async ({ id, data, callBack, user }, { dispatch, getState }) => {
//     const response = await ApiRequests.assignProjectToUsers(id, data);
//     if (response.status === 200) {
//       toast.success("Project Assigned Successfully!");
//     }
//     if (callBack) await callBack();
//     return { data: response?.data, user, id };
//   })
// );

// export const unAssignProjectToUsersAsyncThunk = createAsyncThunk(
//   "project/unAssignProjectToUsersAsyncThunk",
//   catchAsync(async ({ id, data, callBack, userId }, { dispatch, getState }) => {
//     const response = await ApiRequests.unAssignProjectToUsers(id, data);
//     if (response.status === 200) {
//       toast.success("Project UnAssigned Successfully!");
//     }
//     if (callBack) await callBack();
//     return { data: response?.data, id, userId };
//   })
// );

// // GET WORKSPACE
// export const getWorkSpaceAsyncThunk = createAsyncThunk(
//   "user/getWorkSpaceAsyncThunk",
//   catchAsync(async ({ params }, { dispatch, getState }) => {
//     const response = await ApiRequests.getWorkSpace(params);
//     return response?.data;
//   })
// );

export const editDocumentAsyncThunk = createAsyncThunk(
  "user/editDocumentAsyncThunk",
  catchAsync(async ({ id, data, callBack }, { dispatch, getState }) => {
    const response = await ApiRequests.updateDocument({ id, data });
    if (response.status === 200) {
      toast.success("Document Updated Successfully!");
    }
    if (callBack) await callBack();

    return response?.data;
  })
);

export const deleteDocumentAsyncThunk = createAsyncThunk(
  "user/deleteDocumentAsyncThunk",
  catchAsync(async ({ id, callBack }, { dispatch, getState }) => {
    const response = await ApiRequests.deleteDocument(id);
    if (response.status === 204) {
      toast.success("Document Deleted Successfully!");
    }
    if (callBack) await callBack();
    return id;
  })
);

///////////////////////////////////////////////////

const initialState = {
  //news states
  users: {
    page: 0,
    users: [],
    totalPages: 1,
  },
  usersCount: {
    page: 0,
    results: [],
    totalPages: 1,
  },
  inviteUser: {
    page: 0,
    results: [],
    totalPages: 1,
  },
  userExport: {
    page: 0,
    results: [],
    totalPages: 1,
  },
  userRole: {
    page: 0,
    results: [],
    totalPages: 1,
  },
  usersList: {
    page: 0,
    results: [],
    totalPages: 1,
  },
  projects: {
    page: 0,
    results: [],
    totalPages: 1,
  },
  documents: {
    page: 0,
    results: [],
    totalPages: 1,
  },
  project: {},
  WorkSpace: {
    page: 0,
    results: [],
    totalPages: 1,
  },
  story: null,
  assets: null,
  asset: null,
  listings: {
    page: 0,
    results: [],
    totalPages: 1,
  },
  // manager states
  errors: {},
  loadings: {},
  errorMessages: {},
  errorCodes: {},
  paramsForThunk: {},
  search: null,
  categoryId: null,
  categories: [],
  order: "asce",
  userIds: [],
};

const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setSearchValue(state, action) {
      state.search = action.payload;
    },
    setCategoryValue(state, action) {
      state.categoryId = action.payload;
    },
    setOrderValue(state, action) {
      state.order = action.payload;
    },
    storeUserIds: (state, action) => {
      state.userIds = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //
      .addCase(getUsersAsyncThunk.pending, (state, action) => {
        if (action.meta?.arg?.page <= 1 || !action.meta?.arg?.page) {
          state.users = {
            page: 0,
            results: [],
            totalPages: 1,
          };
        }
      })

      .addCase(getUsersAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.page > 1) {
          state.users = {
            ...action.payload,
            users: state?.users?.results.concat(action?.payload?.results),
          };
        } else {
          state.users = action.payload;
        }
      })

      .addCase(getUsersAsyncThunkNew.fulfilled, (state, action) => {
        if (action.payload?.page > 1) {
          state.userIds = {
            ...action.payload,
            users: state?.userIds?.results.concat(action?.payload?.results),
          };
        } else {
          state.userIds = action.payload;
        }
      })

      .addCase(membersCheckUsernameAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.page > 1) {
          state.usersCount = {
            ...action.payload,
            results: state?.usersCount?.results.concat(
              action?.payload?.results
            ),
          };
        } else {
          state.usersCount = action.payload;
        }
      })

      .addCase(filterClassBaseStudentsAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.page > 1) {
          state.users = {
            ...action.payload,
            results: state?.users?.results.concat(action?.payload?.results),
          };
        } else {
          state.users = action.payload;
        }
      })

      .addCase(getUserAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.page > 1) {
          state.story = {
            ...action.payload,
            results: state?.story?.results.concat(action?.payload?.results),
          };
        } else {
          state.story = action.payload;
        }
      })
      .addCase(deleteUserAsyncThunk.fulfilled, (state, action) => {
        state.users = {
          ...state.users,
          totalResults: state.users?.totalResults - 1,
          results: state.users?.results.filter((e) => e.id !== action.payload),
        };
        state.usersCount = {
          ...state.usersCount,
          totalResults: state.usersCount?.totalResults - 1,
          results: state.usersCount?.results.filter(
            (e) => e.id !== action.payload
          ),
        };
      })

      .addCase(deleteMultipleUsersAsyncThunk.fulfilled, (state, action) => {
        state.users = {
          ...state.users,
          totalResults: state.users?.totalResults - 1,
          results: state.users?.results.filter((e) => e.id !== action.payload),
        };
        state.usersCount = {
          ...state.usersCount,
          totalResults: state.usersCount?.totalResults - 1,
          results: state.usersCount?.results.filter(
            (e) => e.id !== action.payload
          ),
        };
      })

      // .addCase(getProjectsAsyncThunk.fulfilled, (state, action) => {
      //   if (action.payload?.page > 1) {
      //     state.projects = {
      //       ...action.payload,
      //       projects: state?.projects?.results.concat(action?.payload?.results),
      //     };
      //   } else {
      //     state.projects = action.payload;
      //   }
      // })
      // .addCase(editProjectAsyncThunk.fulfilled, (state, action) => {
      //   console.log("🚀 ~ .addCase ~ action.payload:", action.payload);

      //   state.projects = {
      //     ...state.projects,
      //     results: state.projects?.results.map((e) => {
      //       if (e.id === action.payload.id) {
      //         return {
      //           ...action.payload,
      //           Task: e.Task,
      //           assignees: e.assignees,
      //           _count: e._count,
      //         };
      //       } else {
      //         return e;
      //       }
      //     }),
      //   };
      // })
      // .addCase(getWorkSpaceAsyncThunk.fulfilled, (state, action) => {
      //   if (action.payload?.page > 1) {
      //     state.WorkSpace = {
      //       ...action.payload,
      //       WorkSpace: state?.WorkSpace?.results.concat(
      //         action?.payload?.results
      //       ),
      //     };
      //   } else {
      //     state.WorkSpace = action.payload;
      //   }
      // })

      // .addCase(getProjectAsyncThunk.fulfilled, (state, action) => {
      //   state.project = action.payload;
      // })

      // .addCase(assignProjectToUsersAsyncThunk.fulfilled, (state, action) => {
      //   state.projects = {
      //     ...state.projects,
      //     results: state.projects?.results.map((e) => {
      //       if (e.id === action.payload.id) {
      //         return {
      //           ...e,
      //           assignees: [
      //             ...e.assignees,
      //             {
      //               createdAt: new Date(),
      //               projectId: e.id,
      //               updatedAt: new Date(),
      //               user: action.payload.user,
      //               userId: action.payload?.user?.userId,
      //             },
      //           ],
      //         };
      //       } else {
      //         return e;
      //       }
      //     }),
      //   };
      // })
      // .addCase(unAssignProjectToUsersAsyncThunk.fulfilled, (state, action) => {
      //   state.projects = {
      //     ...state.projects,
      //     results: state.projects?.results.map((e) => {
      //       if (e.id === action.payload.id) {
      //         return {
      //           ...e,
      //           assignees: e.assignees.filter(
      //             (user) => user.userId !== action.payload.userId
      //           ),
      //         };
      //       } else {
      //         return e;
      //       }
      //     }),
      //   };
      // })

      // im using addMatcher to manage the asyncthunksMehtod actions like fullfilled,pending,rejected and also to manage the errors loading and error messages and async params
      .addMatcher(
        // isAsyncThunk will run when the action is an asyncthunk exists from giver asycntthunks
        isAnyOf(
          // reduxToolKitCaseBuilder helper make fullfilled, pending, and rejected cases
          ...reduxToolKitCaseBuilder([
            getUsersAsyncThunk,
            getUsersAsyncThunkNew,
            membersCheckUsernameAsyncThunk,
            filterClassBaseStudentsAsyncThunk,
            getUserAsyncThunk,
            getUsersByIdsAsyncThunk,
            deleteUserAsyncThunk,
            deleteMultipleUsersAsyncThunk,
            createUserAsyncThunk,
            signInUserAsyncThunk,
            signInWithGoogleAsyncThunk,
            signInWithFacebookAsyncThunk,
            updateUserAsyncThunk,
            // getProjectAsyncThunk,
            // assignProjectToUsersAsyncThunk,
            // unAssignProjectToUsersAsyncThunk,
            // createProjectAsyncThunk,
            // getProjectsAsyncThunk,
            // deleteProjectAsyncThunk,
            // getWorkSpaceAsyncThunk,
            editDocumentAsyncThunk,
            deleteDocumentAsyncThunk,
          ])
        ),
        handleLoadingErrorParamsForAsycThunk
      );
  },
});

export default userSlice.reducer;
export const {
  setLoading,
  setSearchValue,
  setCategoryValue,
  setOrderValue,
  storeUserIds,
} = userSlice.actions;
