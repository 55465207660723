import React, { useEffect } from "react";
import door from "../assets/images/icons/class-room-icon.svg";
import TasksTable from "../components/TasksTable";
import { useDispatch, useSelector } from "react-redux";
import { getAssignTaskAsyncThunk } from "../redux/pagesSlices/taskSlice";
import Loadingdata from "./loadingdata";
const TaskPage = () => {
  const d = useDispatch();
  const assignTasks = useSelector((state) => state.task.Assigntask);
  const loading = useSelector(
    (state) => state.task.loadings?.getAssignTaskAsyncThunk
  );
  useEffect(() => {
    d(getAssignTaskAsyncThunk({ params: { page: 1, limit: 1000 } }));
  }, [d]);

  return (
    <div className="h-100">
      {loading ? (
        <Loadingdata />
      ) : (
        <>
          <TasksTable
            assignTasks={assignTasks}
            headerIcon={door}
            headerTitle="Classroom"
          />
        </>
      )}
      {/* <TasksTable headerIcon={play} headerTitle="Play Area" /> */}
    </div>
  );
};

export default TaskPage;
