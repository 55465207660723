import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { HiOutlineUpload } from "react-icons/hi";
import profile from "../assets/images/profile.png";
import { useUser } from "@clerk/clerk-react";

function AccountSetting() {
  // Redux dispatch

  const { user } = useUser();
  // Set state for dynamic form fields
  const [firstName, setFirstName] = useState(user?.firstName || "");
  const [lastName, setLastName] = useState(user?.lastName || "");
  const [email, setEmail] = useState(
    user?.emailAddresses?.[0]?.emailAddress || ""
  );
  const [loading, setLoading] = useState(false);
  console.log("🚀 ~ AccountSetting ~ loading:", loading);
  const [profilePicture, setProfilePicture] = useState(
    user?.imageUrl || profile
  );


  // const [profilePictureFile, setProfilePictureFile] = useState(null); // Store the file object for upload
  const [showSaveButton, setShowSaveButton] = useState(false);

  useEffect(() => {
    if (
      firstName !== user?.firstName ||
      lastName !== user?.lastName ||
      profilePicture !== user?.imageUrl
    ) {
      setShowSaveButton(true);
    } else {
      setShowSaveButton(false);
    }
  }, [firstName, lastName, profilePicture, user]);

  // Handle file upload for profile picture
  const handleProfilePictureChange = (event) => {
    const file = event.target.files[0];
    try {
      if (!file) throw new Error("No file selected.");
      if (file.type === "image/svg+xml") {
        throw new Error(
          "SVG format is not supported. Please upload a different image format."
        );
      }

      setProfilePicture(file);
      // setProfilePictureFile(file);
    } catch (error) {
      console.error("File upload error:", error.message);
      toast.error(error.message);
    }
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    await user.update({
      firstName: firstName,
      lastName: lastName,
    });
    if (profilePicture instanceof File) {
      await user.setProfileImage({
        file: profilePicture,
      });
    }
    await user.reload();
    setProfilePicture(user?.imageUrl || profile);
    setLoading(false);
    toast.success("User updated successfully");
  };

  return (
    <div className="account-setting">
      <div className="account-setting-wrapper">
        <div className="account-title-wrapper">
          <p>
            Account settings <span className="slash-style">/</span>
          </p>
          <h6>Profile</h6>
        </div>

        <div className="bootom"></div>

        <div className="account-profile-wrapper">
          <div className="container">
            <div className="col-md-12">
              <div className="account-profile">
                <img src={profilePicture instanceof File ? URL.createObjectURL(profilePicture) : profilePicture } alt="Profile" />
                <div className="accounts-wrapper">
                  <span>Profile Picture</span>
                  <div className="account-btn">
                    <label htmlFor="upload" className="btn-style">
                      <HiOutlineUpload /> Upload image
                      <input
                        id="upload"
                        type="file"
                        accept="image/*"
                        onChange={handleProfilePictureChange}
                        style={{ display: "none" }}
                      />
                    </label>
                    {profilePicture instanceof File && (
                      <button
                        className="remove"
                        onClick={() =>
                          setProfilePicture(user?.imageUrl || profile)
                        }
                      >
                        Remove
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* Single form wrapping all inputs */}
            <form className="form" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6">
                  <div className="prfile-form">
                    <div className="mb-3">
                      <label htmlFor="firstName" className="form-label">
                        First name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="firstName"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        placeholder="Robin"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="prfile-form">
                    <div className="mb-3">
                      <label htmlFor="lastName" className="form-label">
                        Last name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="lastName"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        placeholder="From Complya"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="prfile-form">
                    <div className="mb-3">
                      <label htmlFor="email" className="form-label">
                        Primary Email Address
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="robin@complya.com"
                        readOnly={true}
                        style={{ color: "gray" }}
                      />
                    </div>
                  </div>
                </div>

                {/* <div className="col-md-12">
                  <div className="email-content">
                    <Link to="#">Change your email address</Link>
                  </div>
                </div> */}

                {/* Save button moved outside to submit the form */}
                <div className="col-md-12">
                  {showSaveButton ? (
                    <button
                      type="submit"
                      disabled={loading}
                      className="btn btn-primary"
                    >
                      {loading ? "Saving..." : "Save Changes"}
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccountSetting;
