import React from "react";
import profile from "../../src/assets/images/profile.png";
import { ErrorMessage } from "formik";
import uploadIcon from "../assets/images/icons/upload-icon.svg";
function PersoanlCard({ values, handleChange }) {
  return (
    <div className="personal-information">
      <div className="info-wrapper-main">
        <div className="info-wrapper-content">
          <p>1/4</p>
          <h6>Let's get to know you</h6>
          <div className="profile-picture-wrapper">
            <div className="profile-img">
              <img
                src={
                  values?.profileImage instanceof File
                    ? URL.createObjectURL(values?.profileImage)
                    : values?.profileImage || profile
                }
                alt=""
              />
            </div>
            <div className="peofile-content">
              <h6>Profile picture</h6>
              <div className="profile-btn-wrapper">
                <div className="profile-btn">
                  <label htmlFor="upload">
                    <div className="replace">
                      <img src={uploadIcon} alt="icon" />
                      <p>Replace image</p>
                    </div>
                    <input
                      type="file"
                      onChange={(e) => {
                        handleChange({
                          target: {
                            name: "profileImage",
                            value: e.target.files[0],
                          },
                        });
                      }}
                      accept="image/*"
                      id="upload"
                      style={{ display: "none" }}
                    />
                  </label>
                  <ErrorMessage
                    component="div"
                    name="profileImage"
                    className="invalid-feedback"
                  />
                  
                </div>
                {/* <div className='profile-btn'>
                <div className="replace">
                  <p>Remove</p>
                </div> */}
                {/* </div> */}
              </div>
              <p className="mt-3">
                *.png, *.jpeg Docs up to 10MB at least 400px by 400px
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PersoanlCard;
