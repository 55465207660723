import React from "react";
import OnBoardingStepper from "../components/OnBoardingStepper";
import LogoBar from "../components/LogoTopBar";
import SignUpFooter from "../components/SignUpFooter";

export default function OnBoardingPage(){
    return(
        <div className="on-boarding-page">
            <LogoBar/>
            <OnBoardingStepper/>
            <SignUpFooter/>
        </div>
    )
}