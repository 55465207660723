import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux"; // Ensure useDispatch and useSelector are imported
import { Modal, Button, Form } from "react-bootstrap"; // Ensure you import these components from react-bootstrap
import { handleModel } from "../redux/layoutSlices/modelSlice";
import {
  createDocumentAsyncThunk,
  getDocumentsAsyncThunk,
} from "../redux/pagesSlices/docSlice";
import { toast } from "react-toastify";
import { getProjectAsyncThunk } from "../redux/pagesSlices/projectSlice";

const CreateDocModel = () => {
  const d = useDispatch();

  const state = useSelector((state) => state.model?.modelState?.creatDocModel);
  const loading = useSelector(
    (state) => state.doc.loadings?.createDocumentAsyncThunk
  );
  const modelArgs = useSelector(
    (state) => state.model?.modelArgs?.creatDocModel
  );

  const handleClose = () => {
    d(handleModel({ model: "creatDocModel", state: false }));
  };

  const [file, setFile] = useState(null);
  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (file) {
      const data = new FormData();
      data.append("file", file);
      data.append("projectId", modelArgs?.id);

      try {
        // Wait for the thunk to complete
        await d(createDocumentAsyncThunk({ data }));
        toast.success("Document created successfully!");
        // Call handleClose after the document is successfully created
        handleClose();
        
        // Fetch the updated documents
        d(
          getDocumentsAsyncThunk({
            params: { page: 1, limit: 1000 },
            id: modelArgs?.id,
          })
        );
        d(getProjectAsyncThunk({ id: modelArgs?.id }));
      } catch (error) {
        // Handle any errors that occur during the document creation
        console.error("Error creating document:", error);
      }
    } else {
      console.log("No file selected");
    }
  };

  useEffect(() => {
    if (state) {
      d(
        getDocumentsAsyncThunk({
          params: { page: 1, limit: 1000 },
          id: modelArgs?.id,
        })
      );
    }
  }, [state, d, modelArgs?.id]);

  return (
    <div>
      <Modal
        show={state}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Create Document
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>Select Document to Upload</Form.Label>
              <Form.Control
                type="file"
                onChange={handleFileChange}
                accept=".docx, .pdf, .txt"
              />
            </Form.Group>
            <Button variant="primary" type="submit" disabled={loading}>
              {loading ? "Uploading..." : "Upload"}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CreateDocModel;
