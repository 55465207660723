import React from "react";
import work from "../../src/assets/images/work.png";
import { HiOutlineUpload } from "react-icons/hi";
import profile from "../../src/assets/images/profile.png";

function WorkSpace() {
  return (
    <div className="home-page">
      <div className="container mt-5">
        <div className="signup-wrapper-form">
          <div className="row">
            <div className="col-md-6">
              <div className="signup-form-outer">
                <div className="personal-information">
                  <div className="info-wrapper-main">
                    <div className="info-wrapper-content">
                      <p>2/4</p>
                      <h6>Create your workspace</h6>
                      <div className="profile-picture-wrapper">
                        <div className="profile-img">
                          <img src={profile} alt="" />
                        </div>
                        <div className="peofile-content">
                          <h6>Company logo</h6>
                          <div className="profile-btn-wrapper">
                            <div className="profile-btn">
                              <div className="replace">
                                <HiOutlineUpload />
                                <p>Replace image</p>
                              </div>
                            </div>
                            <div className="profile-btn">
                              <div className="replace">
                                <p>Remove</p>
                              </div>
                            </div>
                          </div>
                          <p className="mt-3">
                            *.png, *.jpeg Docs up to 10MB at least 400px by
                            400px
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-wrapper">
                <form action="#" className="form">
                  <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">
                      Company name
                    </label>
                    <input
                      type="email"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Enter your company name"
                    />
                  </div>
                  <button href="" className="btn-style full">
                    Continue
                  </button>
                </form>
              </div>
            </div>
            <div className="col-md-6">
              <div className="welcome-wrapper-main">
                <div className="profile-card-img">
                  <img src={work} alt="profilecard" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WorkSpace;
