import React from 'react'
import { Table, Form } from 'react-bootstrap';

function Notification() {


    return (
        <div className='account-setting'>
            <div className="account-setting-wrapper">
                <div className="tabs">
                    <ul>
                        <li>Workspace settings</li>
                        <li><span className='slash-style'>/</span></li>
                        <li className='active'>Notifications</li>
                    </ul>
                </div>
                <h6 className='notification-heading mt-md-4'>Notifications</h6>
                <div className="bootom"></div>

                <div className="notification-settings ">
                    <Table className="settings-table-wrapper">
                        <thead>
                            <tr>
                                <th>Notify me about</th>
                                <th className="text-center">App</th>
                                <th className="text-center">Email</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <h5>Mentions</h5>
                                    <p>Notify me when someone cites me with an @mention in notes or comments.</p>
                                </td>
                                <td className="text-center">
                                    <Form.Check type="checkbox" label="" checked />
                                </td>
                                <td className="text-center">
                                    <Form.Check type="checkbox" label="" checked />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h5>Replies</h5>
                                    <p>Notify me when someone replies to my comments.</p>
                                </td>
                                <td className="text-center">
                                    <Form.Check type="checkbox" label="" checked />
                                </td>
                                <td className="text-center">
                                    <Form.Check type="checkbox" label="" checked />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h5>Email Grants</h5>
                                    <p>Notify me of email access requested or when my requests are accepted or denied.</p>
                                </td>
                                <td className="text-center">
                                    <Form.Check type="checkbox" label="" checked />
                                </td>
                                <td className="text-center">
                                    <Form.Check type="checkbox" label="" checked />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h5>Task Assignments</h5>
                                    <p>Notify me when I'm assigned a task.</p>
                                </td>
                                <td className="text-center">
                                    <Form.Check type="checkbox" label="" checked />
                                </td>
                                <td className="text-center">
                                    <Form.Check type="checkbox" label="" checked />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h5>Shared Resources</h5>
                                    <p>Notify me when someone shares a resource, such as an email, with me.</p>
                                </td>
                                <td className="text-center">
                                    <Form.Check type="checkbox" label="" checked />
                                </td>
                                <td className="text-center">
                                    <Form.Check type="checkbox" label="" checked />
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            </div>
        </div>
    )
}

export default Notification