import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
// import { updateDriverStatusAsyncThunk } from "../../redux/pagesSlices/driverSlice";
import { handleModel } from "../redux/layoutSlices/modelSlice";
import {
  getProjectsAsyncThunk,
  getWorkSpaceAsyncThunk,
  unAssignProjectToUsersAsyncThunk,
} from "../redux/pagesSlices/projectSlice";
import Loadingdata from "../pages/loadingdata";
import NodataFound from "./NodataFound";
import {
  getProjectTasksAsyncThunk,
  unAssignTaskToUsersAsyncThunk,
} from "../redux/pagesSlices/taskSlice";
import { Spinner } from "react-bootstrap";

function UnAssignModel({ id, status }) {
  const state = useSelector((state) => state.model?.modelState?.UnAssignModel);
  const modelArgs = useSelector(
    (state) => state.model?.modelArgs?.UnAssignModel
  );
  const resource = modelArgs?.resource;
  const workspace = useSelector((state) => state.project.WorkSpace);
  const d = useDispatch();
  const loading1 = useSelector(
    (state) =>resource==="tasks" ? state.task.loadings?.unAssignTaskToUsersAsyncThunk: state.task.loadings?.unAssignProjectToUsersAsyncThunk
  );
  const loading = useSelector(
    (state) => state.project.loadings?.getWorkSpaceAsyncThunk
  );

  const handleClose = () => {
    d(handleModel({ model: "UnAssignModel", state: false }));
  };
  const [selectedAssignees, setSelectedAssignees] = useState([]);

  useEffect(() => {
    if (state) {
      d(getWorkSpaceAsyncThunk({}));
    }
  }, [d, state]);

  const toggleArrayItem = (item) =>
    setSelectedAssignees(
      selectedAssignees.includes(item)
        ? selectedAssignees.filter((i) => i !== item)
        : [...selectedAssignees, item]
    );

  const handleAssignees = () => {
    if (modelArgs?.data?.id && selectedAssignees.length > 0) {
      if (resource === "tasks") {
        d(
          unAssignTaskToUsersAsyncThunk({
            id: modelArgs?.data?.id,
            data: { assignees: selectedAssignees },
            callBack: () => {
              d(
                getProjectTasksAsyncThunk({
                  params: { page: 1, limit: 1000 },
                  id: modelArgs?.data?.projectId,
                })
              );
              handleClose();
              setSelectedAssignees([]);
            },
          })
        );
      } else {
        d(
          unAssignProjectToUsersAsyncThunk({
            id: modelArgs?.data?.id,
            data: { assignees: selectedAssignees },
            callBack: () => {
              d(
                getProjectsAsyncThunk({
                  params: { page: 1, limit: 1000 },
                })
              );
              handleClose();
              setSelectedAssignees([]);
            },
          })
        );
      }
    }
  };

  return (
    <>
      <Modal
        show={state}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="create-project-modal "
      >
        <Modal.Header closeButton>
          <Modal.Title>Unassign User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="task-table-container py-0 px-2">
            {workspace?.results?.length > 0 ? (
              <table className="task-table" style={{borderCollapse:"separate",borderSpacing:"0px 10px"}}>
                <thead>
                  <tr>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <Loadingdata />
                  ) : (
                    <>
                      {workspace?.results?.map((task, idx) => (
                        <tr key={idx}>
                          <td
                            onClick={() => toggleArrayItem(task?.userId)}
                            className={
                              selectedAssignees?.includes(task?.userId)
                                ? "activeTask"
                                : ""
                            }
                            // className="activeTask"
                          >
                            <div className="icon-wrapper">
                              <img src={task.picture} alt="Assignee" />
                              <p>{task.displayName}</p>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </>
                  )}
                </tbody>
              </table>
            ) : (
              <NodataFound title={"Task"} />
            )}
          </div>
        </Modal.Body>
        <Modal.Footer sclassName="model-closing-btn" style={{borderTop:"1.12px solid rgba(0, 0, 0, 0.2509803922)"}}>
          <Button  className="modal-cancel-btn"  onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleAssignees}
            disabled={selectedAssignees.length === 0}
            className="btn-style"
            style={{background:"#7F64FC" , borderColor:"#7F64FC"}}
          >
            {loading1 ? <Spinner size="sm" /> : "Unassign Users"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default UnAssignModel;
