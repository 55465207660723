import React, { useState } from "react";
import team from "../../assets/images/team.png";
import { Badge, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { sendInvitationAsyncThunk } from "../../redux/pagesSlices/workspaceSlice";

function Invitation() {
  const [emails, setEmails] = React.useState([]);
  const d = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector(
    (state) => state.workspace.loadings?.sendInvitationAsyncThunk
  );
  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const sendInvites = async () => {
    // Send invites
    if (emails.length === 0) {
      toast.error("Please add atleast one email");
      return;
    }
    const promises = emails.map((email) => {
      return d(sendInvitationAsyncThunk({ data: email }));
    });

    await Promise.all(promises);
    navigate("/");
  };
  const [email, setEmail] = useState("");
  return (
    <div className="personal-information-step">
      <div className="container mt-5">
        <div className="signup-wrapper-form">
          <div className="row">
            <div className="col-md-6">
              <div className="signup-form-outer">
                <div className="collabrate-team-wrapper">
                  <div className="team-content">
                    {/* <p>4/4</p> */}
                    <h6>Collaborate with your team</h6>
                    <p className="team">
                      The more your teammates use Complya, the more powerful it
                      becomes.
                    </p>
                    <p className="team">
                      We make it incredibly easy to collaborate with your team.
                      From relationship intelligence and sharing communications
                      to workflow building, task management to comments, every
                      part of Complya has been made with the team in mind.
                    </p>
                    <p className="mt-4">
                      Invite people to collaborate in Complya
                    </p>
                  </div>

                  <div className="form-wrapper mt-3 collaborateteamstep_form">
                    <div className="form">
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          const isValid = validateEmail(e.target.email.value);
                          if (!isValid) {
                            toast.error("Please enter a valid email address");
                            return;
                          }
                          // Check if the email already exists
                          const existingEmail = emails.find(
                            (email) => email.email === e.target.email.value
                          );

                          if (existingEmail) {
                            // Update role if it's different
                            if (existingEmail.role !== e.target.role.value) {
                              setEmails((prev) =>
                                prev.map((email) =>
                                  email.email === e.target.email.value
                                    ? { ...email, role: e.target.role.value }
                                    : email
                                )
                              );
                            }
                          } else {
                            // Add new email if it doesn't exist
                            setEmails([
                              ...emails,
                              {
                                email: e.target.email.value,
                                role: e.target.role.value,
                              },
                            ]);
                          }
                          // empty the form
                          e.target.email.value = "";
                          e.target.role.value = "member";
                        }}
                      >
                        <div className="collaborateteamstep_inputs mb-3">
                          <div className="flex-grow-1">
                            <input
                              type="email"
                              name="email"
                              className="form-control"
                              placeholder="example@email.com"
                            />
                          </div>
                          <div>
                            <select
                              class="form-select"
                              defaultValue="member"
                              name="role"
                            >
                              <option value="admin">Admin</option>
                              <option value="member">Member</option>
                            </select>
                          </div>
                        </div>
                      </form>
                      {emails.map((email, index) => (
                        <Badge
                          bg="secondary"
                          onClick={() =>
                            setEmails(emails.filter((_, i) => i !== index))
                          }
                        >
                          {email.email}
                          <Badge bg="success">{email.role}</Badge>
                        </Badge>
                      ))}
                      <button className="btn-style full" onClick={sendInvites}>
                        {loading ? <Spinner size="sm" /> : "Send invites"}
                      </button>
                      <button
                        className="skip-btn full mt-3"
                        onClick={async () => {
                          navigate("/");
                        }}
                      >
                        Back to Home
                      </button>
                    </div>
                  </div>
                  <div className="team-pargraph">
                    <p>
                      By continuing you agree to our{" "}
                      <span style={{ textDecoration: "underline" }}>
                        terms and conditions
                      </span>{" "}
                      . You further agree and understand that Complya services
                      are strictly for business users and you therefore confirm
                      that you have the legal authority to act on behalf of the
                      sole trader or company signing up for Complya service.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="welcome-wrapper-main">
                <div className="profile-card-img">
                  <img src={team} alt="profilecard" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Invitation;
