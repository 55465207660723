import React from "react";
import logo from '../assets/images/icons/complya-logo.png'
import { Link } from "react-router-dom";

export default function LogoBar() {
    return (
        <div className="logo-top-bar">
            <Link to={'/'}>
                <img src={logo} alt="logo" />
            </Link>
        </div>
    )
}