import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import { ApiRequests } from "../../service/ApiRequests";
import {
  catchAsync,
  handleLoadingErrorParamsForAsycThunk,
  reduxToolKitCaseBuilder,
} from "../../helpers/detectError";
import { toast } from "react-toastify";
// import { useNavigate } from "react-router-dom";
// import { red } from "@mui/material/colors";

// Start User Slices
///////////////////////////////////////////////////

// GET WORKSPACE

export const createTaskAsyncThunk = createAsyncThunk(
  "task/createTaskAsyncThunk",
  catchAsync(async ({ data, callBack }, { dispatch, getState }) => {
    const response = await ApiRequests.creatTask(data);
    if (response.status === 201) {
      toast.success("Task Created Successfully!");
    }
    if (callBack) await callBack();
    return response?.data;
  })
);

export const getTasksAsyncThunk = createAsyncThunk(
  "task/getTasksAsyncThunk",
  catchAsync(async ({ params }, { dispatch, getState }) => {
    const response = await ApiRequests.getTasks(params);
    return response?.data;
  })
);
export const getProjectTasksAsyncThunk = createAsyncThunk(
  "task/getProjectTasksAsyncThunk",
  catchAsync(async ({ params, id }, { dispatch, getState }) => {
    const response = await ApiRequests.getProjectTasks(params, id);
    return response?.data;
  })
);

export const getAssignTaskAsyncThunk = createAsyncThunk(
  "task/getAssignTaskAsyncThunk",
  catchAsync(async ({ params }, { dispatch, getState }) => {
    const response = await ApiRequests.assignTask(params);
    console.log("response", response);
    return response?.data;
  })
);

export const editTaskAsyncThunk = createAsyncThunk(
  "task/editTaskAsyncThunk",
  catchAsync(async ({ id, data, callBack, from }, { dispatch, getState }) => {
    const response = await ApiRequests.updateTask({ id, data });
    if (response.status === 200) {
      toast.success("Task Updated Successfully!");
    }
    if (callBack) await callBack();

    return { data: response?.data, from };
  })
);

export const deleteTaskAsyncThunk = createAsyncThunk(
  "task/deleteTaskAsyncThunk",
  catchAsync(async ({ id, callBack }, { dispatch, getState }) => {
    const response = await ApiRequests.deleteTask(id);
    if (response.status === 204) {
      toast.success("Task Deleted Successfully!");
    }
    if (callBack) await callBack();
    return id;
  })
);

export const assignTaskToUsersAsyncThunk = createAsyncThunk(
  "task/assignTaskToUsersAsyncThunk",
  catchAsync(async ({ id, data, callBack,user }, { dispatch, getState }) => {
    const response = await ApiRequests.assignTaskToUsers(id, data);
    if (response.status === 200) {
      toast.success("Task Assigned Successfully!");
    }
    if (callBack) await callBack();
    return {data: response?.data, user, id};
  })
);

export const unAssignTaskToUsersAsyncThunk = createAsyncThunk(
  "task/unAssignTaskToUsersAsyncThunk",
  catchAsync(async ({ id, data, callBack,userId }, { dispatch, getState }) => {
    const response = await ApiRequests.unAssignTaskToUsers(id, data);
    if (response.status === 200) {
      toast.success("Task UnAssigned Successfully!");
    }
    if (callBack) await callBack();
    return {data:response?.data, id, userId};
  })
);

///////////////////////////////////////////////////

const initialState = {
  //news states
  Tasks: {
    page: 0,
    results: [],
    totalPages: 1,
  },
  ProjectTasks: {
    page: 0,
    results: [],
    totalPages: 1,
  },
  Assigntask: {
    page: 0,
    results: [],
    totalPages: 1,
  },
  story: null,
  assets: null,
  asset: null,
  listings: {
    page: 0,
    results: [],
    totalPages: 1,
  },
  // manager states
  errors: {},
  loadings: {},
  errorMessages: {},
  errorCodes: {},
  paramsForThunk: {},
  search: null,
  categoryId: null,
  categories: [],
  order: "asce",
  userIds: [],
};

const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setSearchValue(state, action) {
      state.search = action.payload;
    },
    setCategoryValue(state, action) {
      state.categoryId = action.payload;
    },
    setOrderValue(state, action) {
      state.order = action.payload;
    },
    storeUserIds: (state, action) => {
      state.userIds = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //

      .addCase(getTasksAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.page > 1) {
          state.Tasks = {
            ...action.payload,
            Tasks: state?.Tasks?.results.concat(action?.payload?.results),
          };
        } else {
          state.Tasks = action.payload;
        }
      })

      .addCase(getProjectTasksAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.page > 1) {
          state.ProjectTasks = {
            ...action.payload,
            ProjectTasks: state?.ProjectTasks?.results.concat(
              action?.payload?.results
            ),
          };
        } else {
          state.ProjectTasks = action.payload;
        }
      })

      .addCase(getAssignTaskAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.page > 1) {
          state.Assigntask = {
            ...action.payload,
            Assigntask: state?.Assigntask?.results.concat(
              action?.payload?.results
            ),
          };
        } else {
          state.Assigntask = action.payload;
        }
      })
      .addCase(editTaskAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.from === "assign") {
          state.Assigntask = state.Assigntask?.map((item) => {
            return {
              ...item,
              Task: item?.Task?.map((task) => {
                if (task.id === action.payload.data.id) {
                  return { ...task, ...action.payload.data };
                }
                return task;
              }),
            };
          });
        } else {
          state.ProjectTasks = {
            ...state.ProjectTasks,
            results: state.ProjectTasks?.results.map((item) => {
              if (item.id === action.payload.data.id) {
                return { ...item, ...action.payload.data };
              }
              return item;
            }),
          };
        }
      })

      .addCase(assignTaskToUsersAsyncThunk.fulfilled, (state, action) => {
        state.ProjectTasks = {
          ...state.ProjectTasks,
          results: state.ProjectTasks?.results.map((e) => {
            if (e.id === action.payload.id) {
              return {...e, assignees: [...e.assignees, {createdAt:new Date(),projectId: e.id, updatedAt:new Date(), user: action.payload.user, userId: action.payload?.user?.userId}]};
            } else {
              return e;
            }
          }),
        };
      })
      .addCase(unAssignTaskToUsersAsyncThunk.fulfilled, (state, action) => {
        state.ProjectTasks = {
          ...state.ProjectTasks,
          results: state.ProjectTasks?.results.map((e) => {
            if (e.id === action.payload.id) {
              return {
                ...e,
                assignees: e.assignees.filter(
                  (user) => user.userId !== action.payload.userId
                ),
              };
            } else {
              return e;
            }
          }),
        };
      })


      // im using addMatcher to manage the asyncthunksMehtod actions like fullfilled,pending,rejected and also to manage the errors loading and error messages and async params
      .addMatcher(
        // isAsyncThunk will run when the action is an asyncthunk exists from giver asycntthunks
        isAnyOf(
          // reduxToolKitCaseBuilder helper make fullfilled, pending, and rejected cases
          ...reduxToolKitCaseBuilder([
            createTaskAsyncThunk,
            getTasksAsyncThunk,
            editTaskAsyncThunk,
            deleteTaskAsyncThunk,
            getProjectTasksAsyncThunk,
            getAssignTaskAsyncThunk,
            assignTaskToUsersAsyncThunk,
            unAssignTaskToUsersAsyncThunk,
          ])
        ),
        handleLoadingErrorParamsForAsycThunk
      );
  },
});

export default userSlice.reducer;
export const {
  setLoading,
  setSearchValue,
  setCategoryValue,
  setOrderValue,
  storeUserIds,
} = userSlice.actions;
