import React from "react";
export default function Loadingdata() {
  return (
    <div className="loading-div">
      <div className="cube-loading-outer">
        <div className="cube-wrapper">
          <div className="cube-folding">
            <span className="leaf1"></span>
            <span className="leaf2"></span>
            <span className="leaf3"></span>
            <span className="leaf4"></span>
          </div>
          <span className="loading" data-name="Loading">
            LOADING
          </span>
        </div>
      </div>
    </div>
  );
}
