import React, { useEffect } from "react";
import door from "../assets/images/icons/door.svg";
import DocsTable from "../components/DocsTable";
import { useDispatch, useSelector } from "react-redux";
import { getAssignDocumentsAsyncThunk } from "../redux/pagesSlices/docSlice";
import Loadingdata from "./loadingdata";
const DocumentsPage = () => {
  const d = useDispatch();
  const assignDocuments = useSelector((state) => state.doc.assignDocuments);
  const loading = useSelector(
    (state) => state.doc.loadings?.getAssignDocumentsAsyncThunk
  );

  useEffect(() => {
    d(getAssignDocumentsAsyncThunk({ params: { page: 1, limit: 1000 } }));
  }, [d]);

  return (
    <div className="h-100">
      {loading ? (
        <Loadingdata />
      ) : (
        <DocsTable
          assignDocuments={assignDocuments}
          headerIcon={door}
          headerTitle="Classroom"
        />
      )}
      {/* <DocsTable headerIcon={play} headerTitle="Play Area" /> */}
    </div>
  );
};

export default DocumentsPage;
