import React from "react";
import AccountSetting from "../AccountSetting";

export default function AccountSettingsPage() {;

  return (
    <div className="">
      <div className="">
        <main>
          <AccountSetting />
        </main>
      </div>
    </div>
  );
}
