import React, { useEffect, useState } from "react";
import { LuKey } from "react-icons/lu";
import SignUpFooter from "../components/SignUpFooter";
import LogoBar from "../components/LogoTopBar";
import { useSignIn } from "@clerk/clerk-react";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";

const initialState = {
  code: "",
  password: "",
  confirmPassword: "",
};

function ResetPassword() {
  const { signIn } = useSignIn();
  const [formData, setFormData] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [isMinLength, setIsMinLength] = useState(false);
  const [hasSpecialChar, setHasSpecialChar] = useState(false);

  useEffect(() => {
    setIsMinLength(formData.password.length >= 8);
    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
    setHasSpecialChar(specialCharRegex.test(formData.password));
  }, [formData.password]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.password !== formData.confirmPassword) {
      return toast.error("Passwords do not match");
    }
    if (!isMinLength) {
      return toast.error("Must be at least 8 characters");
    }
    if (!hasSpecialChar) {
      return toast.error("Must contain 1 special character");
    }

    setLoading(true);
    try {
      let resetPassAttempt = await signIn?.attemptFirstFactor({
        strategy: "reset_password_email_code",
        code: formData.code,
        password: formData.password,
      });

      if (resetPassAttempt.status === "complete") {
        window.location.href = "/";
      }
    } catch (error) {
      console.error(error.errors);

      if (error.message) {
        toast.error(error.message);
      } else if (error.errors) {
        const messages = error.errors
          .map((error) => error.longMessage)
          .join(", ");
        toast.error(messages);
      } else {
        toast.error("An unexpected error occurred. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="reset-password">
      <div className="home-page">
        <LogoBar />

        <div className="container mt-5">
          <div className="forget-password-wrapper">
            <div className="key-icon-wrapper">
              <div className="box">
                <LuKey />
              </div>
              <h6>Reset Password</h6>
              <div className="form-text-wrapper">
                <p>
                  Your new password must be different from the previously used password{" "}
                </p>
              </div>
              <div className="form-wrapper">
                <form onSubmit={handleSubmit} className="form">
                  <div className="form-group reset-field">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter code here"
                      maxLength={6}
                      name="code"
                      required
                      value={formData.code}
                      onChange={handleInputChange}
                    />
                    {/* <img src={emailIcon} alt="icon" /> */}
                  </div>
                  <div className="form-group reset-field">
                    <input
                      type="password"
                      className="form-control"
                      placeholder="New password"
                      name="password"
                      required
                      value={formData.password}
                      onChange={handleInputChange}
                    />
                    {/* <img src={emailIcon} alt="icon" /> */}
                  </div>
                  <div className="form-group reset-field">
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Confirm password"
                      name="confirmPassword"
                      required
                      value={formData.confirmPassword}
                      onChange={handleInputChange}
                    />
                    {/* <img src={emailIcon} alt="icon" /> */}
                  </div>

                  <button
                    disabled={loading}
                    type="submit"
                    className="btn-style full"
                  >
                    {loading ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      "Reset Password"
                    )}
                  </button>
                </form>
              </div>
              <div className="form-content-wrapper">
                <div className="checkbox-wrapper">
                  <input type="checkbox" />
                  <p>Must be at least 8 characters.</p>
                </div>
                <div className="checkbox-wrapper">
                  <input type="checkbox" />
                  <p>Must contain at least one special character</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SignUpFooter />
      </div>
    </div>
  );
}

export default ResetPassword;
