import React from "react";
import project from "../../src/assets/images/project.png";
import { FaRegFile } from "react-icons/fa";
import { handleModel } from "../redux/layoutSlices/modelSlice";
import { useDispatch } from "react-redux";

function NoProjectFound({ handleCreateProjectShow }) {
  const d = useDispatch();
  return (
    <div className="no-project-found project_page">
      <div className="image-wrapper">
        <img src={project} alt="project" />
        <div className="project-content">
          <h6>No Project Found</h6>
          <p>
            There aren't currently any projects here.<br></br>
            Create to keep track of your Project.
          </p>
          <div
            className="project-icon"
            onClick={() => {
              d(
                handleModel({
                  model: "CreateProjectModal",
                  state: true,
                  args: {},
                })
              );
            }}
          >
            <button href="#" className="btn-style">
              <FaRegFile /> Create project
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NoProjectFound;
