import React from "react";
import dashboard from "../../src/assets/images/dashboard.png";

function NoDashBoard() {
  return (
    <>
      <div className="no-project-found m-0 align-items-center h-100">
        <div className="image-wrapper">
          <img src={dashboard} alt="dashboard" />
          <div className="project-content mt-3">
            <p>Exciting things are on the way! </p>
            <h6 className="dashboard text-black lh-base">Dashboard is coming soon</h6>
          </div>
        </div>
      </div>
    </>
  );
}

export default NoDashBoard;
