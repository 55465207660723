import React from "react";

import PersoanlCard from "../components/PersoanlCard";
import profilecard from "../assets/images/profilecard.png";
import { useUser } from "@clerk/clerk-react";
import * as Yup from "yup";
import { ErrorMessage, Formik } from "formik";
import { toast } from "react-toastify";
import { ApiRequests } from "../service/ApiRequests";

export default function PersonalInformationStep() {
  const { user } = useUser();
  const userSchema = Yup.object().shape({
    profileImage: Yup.mixed(),
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
  });
  const initialValues = {
    profileImage: user?.imageUrl || null,
    firstName: user?.firstName || "",
    lastName: user?.lastName || "",
  };

  const handleUpdateUser = async (values) => {
    try {
      await user.update({
        firstName: values.firstName,
        lastName: values.lastName,
      });
      if (values.profileImage instanceof File) {
        await user.setProfileImage({
          file: values.profileImage,
        });
      }
      await ApiRequests.updateMetadata({ step: 1 });
      await user.reload();
      toast.success("User updated successfully");
    } catch (error) {
      console.log("🚀 ~ handleUpdateUser ~ error:", error);
      toast.error(
        error.message || "An unexpected error occurred. Please try again."
      );
    }
  };

  return (
    <div className="personal-information-step">
      <div className="container mt-5">
        <div className="signup-wrapper-form">
          <div className="row">
            <div className="col-md-6 pb-md-0 pb-4">
              <div className="signup-form-outer">
                <Formik
                  initialValues={initialValues}
                  enableReinitialize
                  validationSchema={userSchema}
                  onSubmit={handleUpdateUser}
                >
                  {({
                    values,
                    errors,
                    touched,
                    isSubmitting,
                    handleSubmit,
                    handleChange,
                    handleBlur,
                  }) => {
                    console.log(
                      "🚀 ~ PersonalInformationStep ~ errors:",
                      errors
                    );
                    return (
                      <>
                        <PersoanlCard
                          values={values}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                        />
                        <div className="form-wrapper-main">
                          <div action="#" className="form">
                            <div class="mb-3">
                              <label for="firstName" class="form-label ">
                                First name
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                id="firstName"
                                placeholder="Enter your first name"
                                value={values.firstName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name="firstName"
                              />
                              <ErrorMessage
                                component="div"
                                name="firstName"
                                className="invalid-feedback"
                              />
                            </div>
                            <div class="mb-3 ">
                              <label for="lastName" class="form-label">
                                Last name
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                id="lastName"
                                placeholder="Enter your last name"
                                value={values.lastName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <ErrorMessage
                                component="div"
                                name="lastName"
                                className="invalid-feedback"
                              />
                            </div>
                            <div class="mb-3">
                              <label for="email" class="form-label">
                                Email
                              </label>
                              <div className="form-group">
                                <input
                                  type="text"
                                  id="email"
                                  name="email"
                                  value={user?.primaryEmailAddress}
                                  // onChange={handleChange}
                                  // onBlur={handleBlur}
                                  disabled
                                  className="form-control"
                                  placeholder="Enter your email address"
                                  style={{ color: "#9FA1A7" }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="bootom"></div>
                        <div className="toggle-content-wrapper">
                          <div className="toggle-content">
                            <h6>Subscribe to product update Reports</h6>
                            <p>
                              Get the latest updates about features and product
                              updates.
                            </p>
                          </div>
                          <label class="switch">
                            <input type="checkbox" />
                            <span class="slider round"></span>
                          </label>
                        </div>
                        <button
                          type="submit"
                          onClick={handleSubmit}
                          className="btn-style full"
                        >
                          {isSubmitting ? "Loading..." : "Continue"}
                        </button>
                      </>
                    );
                  }}
                </Formik>
              </div>
            </div>
            <div className="col-md-6">
              <div className="welcome-wrapper-main">
                <div className="profile-card-img">
                  <img src={profilecard} alt="profilecard" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
