import React, { useEffect, useState } from 'react'
import notification from "../../src/assets/images/notification.png"
import { ApiRequests } from '../service/ApiRequests'

function Notification() {
  const [notifications, setNotifications] = useState([])
  console.log("🚀 ~ Notification ~ notifications:", notifications)
  // useEffect(() => {
  //   ApiRequests.getNotifications().then((res) => setNotifications(res.data))
  // },[])
  return (
    <div className='no-project-found m-0 h-100 align-items-center'>
    <div className='image-wrapper'>
        <img src={notification} alt="project" />
        <div className='project-content'>
            <h6 className='dashboard'>You don't have any notifications</h6>
            <p>We'll notify you about important updates<br></br>
            and any time you're mentioned on Complya.</p>
            <div className="project-icon">
               <button className='notification-btn'>Notifications Settings</button>
            </div>
        </div>

    </div>
</div>
  )
}

export default Notification