import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { ClerkProvider } from "@clerk/clerk-react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter as Router } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
    <ClerkProvider
      frontendApi={process.env.REACT_APP_CLERK_FRONTEND_API}
      publishableKey={process.env.REACT_APP_CLERK_PUBLISHABLE_KEY}
    >
      <Provider store={store}>
        <Router>
          <App />
        </Router>
        <ToastContainer />
      </Provider>
    </ClerkProvider>
  // </React.StrictMode>
);
