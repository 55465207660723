import React from 'react'
import { useSignUp } from "@clerk/clerk-react";
import SignUpFooter from '../components/SignUpFooter';
import LogoBar from '../components/LogoTopBar';
import { toast } from "react-toastify";
import mailIcon from "../assets/images/icons/mail-icon.svg"


function CheckMail() {
  const { signUp } = useSignUp();
  const handleResendVerification = async () => {
    try {
      const signUpAttempt = await signUp.prepareEmailAddressVerification({
        strategy: "email_link",
        redirectUrl: `${process.env.REACT_APP_FRONTEND_URL}/verified`,
      });
      console.log("signUpAttempt", signUpAttempt);

      if (signUpAttempt.status === "missing_requirements") {
        toast.success("Verification link sent successfully!");
      }
    } catch (error) {
      console.error("Error sending verification link:", error);
      toast.error("An error occurred while sending the verification link.");
    }
  };

  return (
    <div>
    <div className='home-page'>
      <LogoBar />
      <div className="container mt-5">
        <div className='check-email-wrapper'>
            <div className='key-icon-wrapper'>
                <div className='box'>
                <img src={mailIcon} alt="icon" />
                </div>
                <h6>Check your email</h6>
                <p style={{textAlign:"center"}}>We sent a verification link to your email</p>
                <div className='recived-email-content'>
                    <p>Don't received the email? <span onClick={handleResendVerification} style={{ cursor: "pointer" }}><a href="#">Click to resend</a></span></p>
                </div>
            </div>
          </div>
        </div>
      </div>
      <SignUpFooter />
    </div>
  )
}

export default CheckMail

