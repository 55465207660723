import { useAuth, useSession, useSignIn } from "@clerk/clerk-react";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { setLocalValue } from "../helpers/localStorageHelpers";
import { useDispatch } from "react-redux";
import { rejectInvitationAsyncThunk } from "../redux/pagesSlices/workspaceSlice";
import Loadingdata from "./loadingdata";

const WorkSpaceInvite = () => {
  const location = useLocation();
  const { session } = useSession();
  const { getToken, userId } = useAuth();
  const { signIn, setActive: setActiveSignIn } = useSignIn();
  const navigate = useNavigate();
  const { workspaceName, invitedBy } = useParams();
  const [acceptLoading, setAcceptLoading] = useState(false);
  const dispatch = useDispatch();

  // Function to get query parameters
  const getQueryParams = () => {
    const params = new URLSearchParams(location.search);
    return {
      token: params.get("__clerk_ticket"), // Extract the __clerk_ticket value
      accountStatus: params.get("__clerk_status"), // You can extract other params similarly
      email: params.get("email"),
    };
  };

  // Call the function to get the values
  const { token, accountStatus, email } = getQueryParams();
  if (accountStatus === "sign_up") {
    localStorage.setItem("__clerk_ticket", token);
    localStorage.setItem("__clerk_status", "sign_in");
    localStorage.setItem("invite-workspaceName", workspaceName);
    localStorage.setItem("invite-invitedBy", invitedBy);
    localStorage.setItem("invite-email", email);
    navigate("/signup?email=" + email);
  } else {
    localStorage.removeItem("__clerk_ticket");
    localStorage.removeItem("__clerk_status");
    localStorage.removeItem("invite-workspaceName");
    localStorage.removeItem("invite-invitedBy");
  }

  const handleSignOut = async () => {
    try {
      await session.end();
      localStorage.removeItem("access-token");
      localStorage.removeItem("user-id");
      localStorage.removeItem("user");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  const handleAcceptInvite = async () => {
    if (!signIn || !setActiveSignIn || !token) {
      return;
    }
    setAcceptLoading(true);
    try {
      await acceptInvitation();
    } catch (error) {
      if (error.errors) {
        await handleSignOut();

        try {
          await acceptInvitation();
        } catch (retryError) {
          console.error("Error retrying invitation acceptance:", retryError);
          handleError(retryError);
        }
      } else {
        handleError(error);
      }
    } finally {
      setAcceptLoading(false);
    }
  };

  // Function to encapsulate the invitation acceptance logic
  const acceptInvitation = async () => {
    if (accountStatus === "sign_in") {
      const signInAttempt = await signIn.create({
        strategy: "ticket",
        ticket: token,
      });
      if (signInAttempt.status === "complete") {
        await setActiveSignIn({
          session: signInAttempt.createdSessionId,
        });
        let token = await getToken();
        setLocalValue("access-token", token);
        setLocalValue("user-id", userId);
        navigate(`/`);
      }
    } else {
      navigate("/signup");
      toast.error("Your account not found. Please sign up and retry.");
    }
  };

  // Function to handle error messages
  const handleError = (error) => {
    if (error.message) {
      toast.error(error.message);
    } else if (error.errors) {
      console.log("error.errors", error.errors);
      const messages = error.errors
        .map((err) => {
          if (err.code === "session_exists") {
            return "You can only be signed into one account at a time. Please sign out and try again.";
          }
          return err.longMessage;
        })
        .join(", ");

      toast.error(messages);
    } else {
      toast.error("An unexpected error occurred. Please try again.");
    }
  };

  useEffect(() => {
    handleAcceptInvite();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {acceptLoading ? <Loadingdata /> : null}
      {/* <div className="bg-image fixed-page">
          <div className="container">
            <div className="form-wrapper">
              <div className="heading-wrapper">
                <h2 style={{ fontWeight: 500 }}>Workspace Invite </h2>
              </div>
  
              <div className="verify-title">
                <p>
                  You are invited to join the workspace <br></br>{" "}
                  <span> {workspaceName} </span>
                  by {invitedBy}.
                </p>
              </div>
  
              <div className="decline-btn">
                <button
                  onClick={handleDeclineInvite}
                  disabled={loadings.rejectInvitationAsyncThunk}
                  className="danger"
                >
                  {loadings.rejectInvitationAsyncThunk ? (
                    <Spinner animation="border" role="status" size="sm" />
                  ) : (
                    "Decline"
                  )}
                </button>
                <button
                  className="primary"
                  disabled={acceptLoading}
                  onClick={handleAcceptInvite}
                >
                  
                </button>
              </div>
            </div>
          </div>
        </div> */}
    </>
  );
};

export default WorkSpaceInvite;
