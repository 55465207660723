export const tourSteps = [
  {
    selector: ".dashboard-tooltip",
    content:
      "Get an overview of your workspace. Monitor key metrics and recent activities.",
  },
  {
    selector: ".inbox-tooltip",
    content:
      "Your inbox for important updates. Keep track of notifications and actions.",
  },
  {
    selector: ".projects-tooltip",
    content:
      "Manage your compliance projects efficiently. Track tasks, milestones, and team progress.",
  },
  {
    selector: ".tasks-tooltip",
    content:
      "Track and manage all assigned tasks. Stay on top of deadlines and priorities.",
  },
  {
    selector: ".docs-tooltip",
    content:
      "Access all documents in one place. Organize and manage compliance paperwork.",
  },
  {
    selector: ".management-tooltip",
    content:
      "Access help, invite team members, and manage your account. Centralize your workspace settings and support.",
  },
];
