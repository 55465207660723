import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  createNoteAsyncThunk,
  editNoteAsyncThunk,
  getNotesAsyncThunk,
} from "../redux/pagesSlices/noteSlice";
import { handleModel } from "../redux/layoutSlices/modelSlice";
import { getProjectAsyncThunk } from "../redux/pagesSlices/projectSlice";

const noteSchema = Yup.object().shape({
  title: Yup.string().required("Note title is required").max(100),
  content: Yup.string().required("Note content is required"),
});

function CreateNoteModel() {
  const dispatch = useDispatch();
  const show = useSelector((state) => state.model?.modelState?.CreateNoteModal);
  const modelArgs = useSelector(
    (state) => state.model?.modelArgs?.CreateNoteModal
  );
  const editId = modelArgs?.editId;
  const formData = modelArgs?.formData;

  const [initialValues, setInitialValues] = useState({
    title: "",
    content: "",
  });

  useEffect(() => {
    if (editId) {
      setInitialValues({
        title: formData?.title || "",
        content: formData?.content || "",
      });
    }
  }, [editId, formData]);

  const getData = () => {
    dispatch(
      getNotesAsyncThunk({
        id: modelArgs?.id,
        params: { page: 1, limit: 1000 },
      })
    );
  };

  const handleClose = () =>
    dispatch(handleModel({ model: "CreateNoteModal", state: false }));

  const handleCreateNote = async (values, { setSubmitting }) => {
    if (editId) {
      await dispatch(
        editNoteAsyncThunk({
          id: editId,
          data: { ...values },
          callBack: () => {
            getData();
            dispatch(getProjectAsyncThunk({ id: modelArgs?.id }));
          },
        })
      );
    } else {
      await dispatch(
        createNoteAsyncThunk({
          data: { ...values, projectId: modelArgs?.id },
          callBack: () => {
            getData();
            dispatch(getProjectAsyncThunk({ id: modelArgs?.id }));
          },
        })
      );
    }
    handleClose();
    setSubmitting(false); // Reset submitting state
  };

  return (
    <Modal
      show={show}
      onHide={() => handleClose()}
      className="create-project-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>{editId ? "Update Note" : "Add Note"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={noteSchema}
          onSubmit={handleCreateNote}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="prg-name-div">
                <div className="project-name-text">
                <Field
                  type="text"
                  name="title"
                  placeholder="Enter your note"
                  className="form-control fw-bold fs-3 mb-2 shadow-none"
                />
                <ErrorMessage
                  name="title"
                  component="div"
                  className="text-danger"
                  style={{ fontSize: "12px", marginTop: "-14px" }}
                />
                </div>
              

              <div className="form-control mb-4">
                <Field
                  as="textarea"
                  name="content"
                  placeholder="Enter Note description"
                  className="form-control mt-2 text-secondary shadow-none"
                />
                <ErrorMessage
                  name="content"
                  component="div"
                  className="text-danger"
                  style={{ fontSize: "12px", marginTop: "-6px" }}
                />
              </div>
              </div>

              <div className="model-closing-btn modal-footer" style={{borderTop:"1.12px solid rgba(0, 0, 0, 0.2509803922)"}}>
              <Button type="submit" className="btn-style" disabled={isSubmitting}>
                {isSubmitting ? "Saving..." : "Save Note"}
              </Button>

              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}

export default CreateNoteModel;
