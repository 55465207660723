import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import ComplyaSidebar from "../../components/ComplyaSidebar";
import { useAuth, useUser } from "@clerk/clerk-react";
import { useTour } from "@reactour/tour";
import { ApiRequests } from "../../service/ApiRequests";

export default function Dashboard() {
  const { isSignedIn } = useAuth();
  const { user } = useUser();

  const { setIsOpen } = useTour();

  const handleTour = async () => {
    const isLargeScreen = window.innerWidth > 992;

    if (!user?.publicMetadata?.isTourOpen && isSignedIn && isLargeScreen) {
      setIsOpen(true);
      await ApiRequests.updateMetadata({ step: 3, isTourOpen: true });
    }
  };

  useEffect(() => {
    handleTour();
  }, [isSignedIn, setIsOpen, user]);

  return (
    <div className="dashboard-page">
      <div className="dashboard-main-wrapper">
        <ComplyaSidebar />
        <div className="dashboard-inner-content-main">
          <main className="h-100">
            <Outlet />
          </main>
        </div>
      </div>
    </div>
  );
}
