import React, { useEffect, useState } from "react";
import PersonalInformationStep from "./PersonalInformationStep";
import CreateYourWorkSpaceStep from "./CreateYourWorkspaceStep";
import CollabrateTeam from "../pages/CollaborateTeam";
import { useNavigate } from "react-router-dom";
import { useUser } from "@clerk/clerk-react";

export default function OnBoardingStepper() {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);
  const { user } = useUser();
  useEffect(() => {
    if (user) {
      if (
        !user?.publicMetadata?.step ||
        Number(user?.publicMetadata?.step) < 1
      ) {
        setCurrentStep(0);
      } else if (Number(user?.publicMetadata?.step) >= 1) {
        navigate("/");
      }
    }
  }, [navigate, user]);

  return (
    <div className="onboarding-stepper">
      <div className="stepper-component-main-wrapper wrapper">
        <div className="container p-0">
          <div className="stepperContainer ">
            <div className="content">
              {currentStep === 0 && <PersonalInformationStep />}
              {currentStep === 1 && <CreateYourWorkSpaceStep />}
              {currentStep === 2 && <CollabrateTeam />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
