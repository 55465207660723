import axios from "axios";
import { apiBasePath } from "../constants";

const apiService = axios.create();
apiService.defaults.baseURL = apiBasePath;
apiService.defaults.timeout = 35000;

// Intercept requests and add the access token to the headers
apiService.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("access-token");
    const workspace = JSON.parse(localStorage.getItem("workspace"))
    const isFormData = config.data instanceof FormData;
    // const token = await getToken();
    config.headers = {
      Accept: "application/json, text/plain, */*",
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": isFormData ? "multipart/form-data" : "application/json",
      "Access-Control-Allow-Origin": "*",
      "access-control-allow-headers": "*",
      "access-control-allow-methods": "*",
      "access-control-allow-origin": "*",
      "Workspace-Id": workspace?.id,
    };
    config.withCredentials = true;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default apiService;
