import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Dropdown from "react-bootstrap/Dropdown";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getProjectAsyncThunk, getWorkSpaceAsyncThunk } from "../redux/pagesSlices/projectSlice";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import vector from "../assets/images/icons/Vector.png";
import status from "../assets/images/icons/Frame (1).png";
import assign from "../assets/images/icons/Frame (3).png";
import date from "../assets/images/icons/Frame (4).png";
import todo from "../assets/images/icons/status1.svg";
import progress from "../assets/images/icons/progress.svg";
import done from "../assets/images/icons/statusicon4.svg";
import due from "../assets/images/icons/x-Vector.svg"
import low from "../assets/images/icons/low.png";
import urgent from "../assets/images/icons/svg9.svg";
import { handleModel } from "../redux/layoutSlices/modelSlice";
import {
  createTaskAsyncThunk,
  editTaskAsyncThunk,
  getProjectTasksAsyncThunk,
  getTasksAsyncThunk,
} from "../redux/pagesSlices/taskSlice";
import medium from "../assets/images/icons/svg7.svg";
import high from "../assets/images/icons/svg8.svg";
import { formatStatus } from "../helpers/globle";

const projectSchema = Yup.object().shape({
  name: Yup.string().required("Task name is required").max(100),
  description: Yup.string().required("Task description is required"),
  assignees: Yup.array(),
  status: Yup.string().required("Status is required"),
  priority: Yup.string().required("Priority is required"),
});

function CreateTaskModal() {
  const state = useSelector(
    (state) => state.model?.modelState?.CreateTaskModal
  );
  const modelArgs = useSelector(
    (state) => state.model?.modelArgs?.CreateTaskModal
  );
  const editId = modelArgs?.editId;
  const formData = modelArgs?.formData;
  const d = useDispatch();
  const workspace = useSelector((state) => state.project.WorkSpace);
  const loading = useSelector(
    (state) => state.task.loadings?.createTaskAsyncThunk || state.task.loadings?.editTaskAsyncThunk
  );

  const [isDateDropdownOpen, setIsDateDropdownOpen] = useState(false);
  const [initialValues, setInitialValues] = useState({
    name: "",
    description: "",
    assignees: [],
    status: "todo",
    priority: "low",
    dueDate: new Date(),
  });

  useEffect(() => {
    if (editId !== "" && state) {
      setInitialValues({
        name: formData?.name|| '',
        description: formData?.description || '',
        status: formData?.status || 'todo',
        priority: formData?.priority || 'low',
        dueDate: formData?.dueDate || new Date(),
      });
    }
  }, [editId, state, formData]);

  const handleClose = () => {
    d(handleModel({ model: "CreateTaskModal", state: false }));
  };

  useEffect(() => {
    if (editId !== "" && state) {
      d(getTasksAsyncThunk({ id: editId }));
    }
  }, [editId, d, state]);

  useEffect(() => {
    if(state){
    d(getWorkSpaceAsyncThunk({}));
    }
  }, [d, state]);

  const handelCreatTask = (values, projectId) => {
    const action = editId ? editTaskAsyncThunk : createTaskAsyncThunk;
    if (editId) {
      delete values.assignees;
    }
    d(
      action({
        id: editId,
        data: { ...values, projectId: modelArgs?.id },
        callBack: () => {
          d(
            getProjectTasksAsyncThunk({
              id: modelArgs?.id,
              params: { page: 1, limit: 1000 },
            })
          );
          d(getProjectAsyncThunk({ id: modelArgs?.id }));
          handleClose();
          setInitialValues({
            name: "",
            description: "",
            assignees: [],
            status: "todo",
            priority: "low",
            dueDate: new Date(),
          });
        },
      })
    );
  };

  return (
    <>
      <Modal show={state} onHide={handleClose} className="create-project-modal">
        <Modal.Header closeButton>
          <Modal.Title> {editId ? "Update Task" : "Add Task"} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={projectSchema}
            onSubmit={handelCreatTask}
          >
            {({ setFieldValue, values }) => (
              <Form>
                <div className="prg-name-div">
                  <img className="icon" src={vector} alt="vectore" />
                  <div className="project-name-text">
                    <Field
                      type="text"
                      value={values.name}
                      onChange={(e) => setFieldValue("name", e.target.value)}
                      placeholder="Enter Task Name"
                      name="name"
                      className="form-control fw-bold fs-3 mb-2 shadow-none"
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="text-danger"
                      style={{ fontSize: "12px", marginTop: "-14px" }}
                    />
                  </div>

                  <div className="form-control">
                    <Field
                      as="textarea"
                      value={values.description}
                      onChange={(e) => setFieldValue("description", e.target.value)}
                      placeholder="Enter Task Description"
                      name="description"
                      className="form-control mt-2 text-secondary shadow-none"
                    />
                    <ErrorMessage
                      name="description"
                      component="div"
                      className="text-danger"
                      style={{ fontSize: "12px", marginTop: "-6px" }}
                    />
                  </div>
                </div>

                <div className="dropdown-btn-row">
                  {/* Status Dropdown */}
                  <Dropdown
                    onSelect={(status) => setFieldValue("status", status)}
                    className="status-dropdown"
                  >
                    <Dropdown.Toggle id="dropdown-basic">
                      <div className="d-flex gap-1 align-items-center">
                        {values.status === "todo" ? (
                          <img
                            className="status-icon"
                            src={todo}
                            alt="status"
                          />
                        ) : values.status === "in_progress" ? (
                          <img src={progress} alt="progresss" className="status-icon"/>
                        ) : values.status === "backlog" ? (
                          <img src={status} alt="progresss" className="status-icon"/>
                        ) : values.status === "done" ? (
                          <img src={done} alt="done" />
                        ) : (
                          <img
                            className="status-icon"
                            src={status}
                            alt="status"
                          />
                        )}
                        <span style={{textTransform: 'capitalize'}} className="text-black">
                        {values.status
                          ? formatStatus(values.status)
                          : "Select Status"}
                          </span>
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item eventKey="todo">
                        <div className="d-flex gap-1 align-items-center">
                          <img src={todo} alt="status" />
                          <p className="mb-0"> Todo</p>
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="in_progress">
                        <div className="d-flex gap-1 align-items-center">
                          <img src={progress} alt="progresss" />
                          <p className="mb-0">In Progress</p>
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="backlog">
                        <div className="d-flex gap-1 align-items-center">
                          <img src={status} alt="progresss" />
                          <p className="mb-0">Backlog</p>
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="done">
                        <div className="d-flex gap-1 align-items-center">
                          <img src={done} alt="done" />
                          <p className="mb-0">Done</p>
                        </div>
                      </Dropdown.Item>
                      <br />
                      <Dropdown.Item eventKey="due">
                        <div className="d-flex gap-1 align-items-center">
                          <img src={due} alt="due" />
                          <p className="mb-0">
                          Due
                          </p>
                        </div>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <ErrorMessage
                    name="status"
                    component="div"
                    className="text-danger"
                    style={{ fontSize: "12px" }}
                  />

                  {/* Priority Dropdown */}
                  <Dropdown
                    className="status-dropdown"
                    onSelect={(priority) => setFieldValue("priority", priority)}
                  >
                    <Dropdown.Toggle id="dropdown-basic">
                      <div className="d-flex gap-1 align-items-center">
                        {values.priority === "low" ? (
                          <img src={low} alt="done" />
                        ) : values.priority === "medium" ? (
                          <img src={medium} alt="done" />
                        ) : values.priority === "high" ? (
                          <img src={high} alt="done" />
                        ) : values.priority === "high" ? (
                          <img src={high} alt="done" />
                        ) : values.priority === "urgent" ? (
                          <img src={urgent} alt="done" />
                        ) : (
                          <img src={low} alt="done" />
                        )}

                        <span>
                        {values.priority
                          ? values.priority.charAt(0).toUpperCase() +
                            values.priority.slice(1)
                          : "Select Priority"}
                          </span>
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item eventKey="low">
                        <div className="d-flex gap-1 align-items-center">
                          <img src={low} alt="done" />
                          <p className="mb-0">Low</p>
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="medium">
                        <div className="d-flex gap-1 align-items-center">
                          <img src={medium} alt="done" />
                          <p className="mb-0">Medium</p>
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="high">
                        <div className="d-flex gap-1 align-items-center">
                          <img src={high} alt="done" />
                          <p className="mb-0">High</p>
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="urgent">
                        <div className="d-flex gap-1 align-items-center">
                          <img src={urgent} alt="done" />
                          <p className="mb-0">Urgent</p>
                        </div>
                      </Dropdown.Item>
                      {/* <Dropdown.Item eventKey="Priority">
                        <div className="d-flex gap-1 align-items-center">
                          <img src={Priority} alt="Priority" />
                          <span style={{ fontSize: "12px", fontWeight: "400" }}>
                          Priority
                          </span>
                        </div>
                      </Dropdown.Item> */}
                    </Dropdown.Menu>
                  </Dropdown>
                  <ErrorMessage
                    name="priority"
                    component="div"
                    className="text-danger"
                    style={{ fontSize: "12px" }}
                  />

                  {/* Assignee Dropdown */}
                  {!editId && (
                    <Dropdown
                      onSelect={(userId) =>
                        setFieldValue("assignees", [userId])
                      }
                      className="model_dropdown"
                    >
                      <Dropdown.Toggle id="dropdown-basic">
                        <div className="d-flex gap-1 align-items-center">
                          <img
                            className="status-icon"
                            src={assign}
                            alt="asign"
                          />
                          <span>
                          {values.assignees?.length
                            ? workspace.results.find(
                                (user) => user?.userId === values.assignees[0]
                              )?.displayName || "Select Assignee"
                            : "Select Assignee"}
                          </span>                          
                        </div>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                      <p>Members</p>
                        {workspace?.results?.map((item) => (
                          <Dropdown.Item
                            key={item?.userId}
                            eventKey={item?.userId}
                            style={{paddingBottom:"6px"}}
                          >
                            {item?.displayName}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                  <ErrorMessage
                    name="assignees"
                    component="div"
                    className="text-danger"
                    style={{ fontSize: "12px", marginTop: "-6px" }}
                  />
                  {/* Due Date */}
                    <Dropdown
                      show={isDateDropdownOpen}
                      onToggle={() =>
                        setIsDateDropdownOpen(!isDateDropdownOpen)
                      }
                    >
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        <div className="d-flex gap-1 align-items-center">
                          <img className="status-icon" src={date} alt="date" />
                          <span>
                          {values?.dueDate
                            ? new Date(values?.dueDate)
                                ?.toLocaleDateString("en-GB")
                                .replace(/\//g, "/")
                            : "Due Date"}
                            </span>
                        </div>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <div className="datepicker-wrapper">
                          <DatePicker
                            minDate={new Date()}
                            value={values.dueDate}
                            onChange={(date) => {
                              setFieldValue("dueDate", date);
                              setIsDateDropdownOpen(false); // Close dropdown after selecting a date
                            }}
                            inline
                          />
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                </div>

                <Modal.Footer>
                  <Button className="modal-cancel-btn" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button className="btn-style" type="submit">
                    {loading ? (
                      <Spinner size="sm" />
                    ) : editId ? (
                      "Update Task"
                    ) : (
                      "Create Task"
                    )}
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CreateTaskModal;
