import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap';
import { CiSearch } from "react-icons/ci";
import { RiUserAddLine } from "react-icons/ri";
import profile from "../assets/images/profile.png"
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';




function Member() {
  const selectedWorkSpace = useSelector((state) => state.workspace.workspace);
  const [memberships, setMemberships] = useState([]);
  const getMemberships = async () => {
      const memberships = await selectedWorkSpace.getMemberships()
      setMemberships(memberships)
  }
    useEffect(() => {
        if(selectedWorkSpace){
            getMemberships()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selectedWorkSpace])
    return (
        <div className='account-setting'>
            <div className="account-setting-wrapper">
                 <div className="tabs">
                    <ul>
                        <li>Workspace settings</li>
                        <li><span className='slash-style'>/</span></li>
                        <li className='active'>Members</li>
                    </ul>
                 </div>
                 <h6 className='mt-4'>Members</h6>
                <p>Manage members and users of your workspace and set their access level. You can invite new users up to the seats<br></br>
                    allowed on your plan.</p>
                <div className="bootom"></div>
                <div className="search-bar">
                    <div className="search-bar-wrapper" style={{ width: "80%" }}>
                        <div className='form-wrapper'>
                            <form action="#" className="form">
                                <div className="form-group">
                                    <input type="text" className="form-control" placeholder='Search' />
                                    <CiSearch />
                                </div>
                            </form>
                        </div>
                    </div>
                    <Link to={'/invitation'} className='btn-style'><RiUserAddLine /> Invite member</Link>
                </div>
                <div className="user-table-container">
                    <Table>
                        <thead>
                            <tr>
                                <th>USER</th>
                                <th>ACCESS LEVEL</th>
                            </tr>
                        </thead>
                        <tbody>
                            {memberships?.data?.map((membership) => (
                            <tr>
                                <td>
                                    <div className="user-info">
                                        <img src={membership?.publicUserData?.imageUrl||profile} alt="profle" />
                                        <span>{membership?.publicUserData?.identifier}</span>
                                    </div>
                                </td>
                                <td style={{ textTransform: "capitalize" }}>
                                    {membership?.role.split(':')[1]}
                                </td>
                            </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </div>
        </div>
    )
}

export default Member