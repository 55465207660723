import React, { useEffect } from "react";
import { Routes, Route, Outlet, useLocation, Navigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
import SignIn from "./pages/SignIn";
import ForgetPassword from "./pages/ForgetPassword";
import CheckMail from "./pages/CheckMail";
import EmailVerified from "./pages/EmailVerified";
import SignUp from "./pages/SignUp";
import PersonalInfo from "./pages/PersonalInfo";
import Dashboard from "./pages/dashboard/Dashboard";
import WorkSpace from "./pages/WorkSpace";
import CollaborateTeam from "./pages/CollaborateTeam";
import Projects from "./pages/dashboard/Projects";
import General from "./pages/General";
import ProjectDetail from "./pages/ProjectDetail";
import Member from "./pages/Member";
import Notification from "./pages/Notification";
import AccountSettingsPage from "./pages/dashboard/AccountSettings";
import { useAuth, useUser } from "@clerk/clerk-react";
import NoDashBoard from "./components/NoDashBoard";
import InboxPage from "./pages/dashboard/InboxPage";
import OAuthCallback from "./components/OAuthCallback";
import OnBoardingPage from "./pages/OnBoardingPage";
import WorkSpaceInvite from "./pages/WorkSpaceInvite";
import DocumentsPage from "./pages/Docs";
import TaskPage from "./pages/Tasks";
import ResetPassword from "./pages/ResetPassword";
import Loadingdata from "./pages/loadingdata";
import Model from "./components/model";
import Invitation from "./pages/dashboard/Invitation";
import { useSelector } from "react-redux";
import { TourProvider } from "@reactour/tour";
import { tourSteps } from "./helpers/tourSteps";
import CreateYourWorkSpaceStep from "./components/CreateYourWorkspaceStep";

export default function App() {
  const { getToken, isSignedIn, userId } = useAuth();
  const radius = 10;
  const selectedWorkSpace = useSelector((state) => state.workspace.workspace);

  useEffect(() => {
    const refreshToken = async () => {
      try {
        let token = await getToken({ template: "AccessToken" });
        localStorage.setItem("access-token", token);
        localStorage.setItem("user-id", userId);
      } catch (error) {
        console.error("Error in fetching token:", error);
      }
    };

    refreshToken();
  }, [isSignedIn, userId, getToken]);

  const localOrg = localStorage.getItem("workspace");
  return (
    <TourProvider
      steps={tourSteps}
      showDots={false}
      badgeContent={({ totalSteps, currentStep }) =>
        currentStep + 1 + " of " + totalSteps
      }
      styles={{
        popover: (base) => ({
          ...base,
          "--reactour-accent": "#ef5a3d",
          borderRadius: radius,
        }),
        maskArea: (base) => ({
          ...base,
          rx: radius,
        }),
        // maskWrapper: (base) => ({ ...base, color: "#ef5a3d" }),
        badge: (base) => ({ ...base, left: "auto", right: "-0.8125em" }),
        // controls: (base) => ({ ...base, marginTop: 100 }),
        close: (base) => ({ ...base, right: "auto", left: 18, top: 8 }),
      }}
    >
      <Model />
      <Routes key={selectedWorkSpace?.id || JSON.parse(localOrg)?.id}>
        <Route path="/" element={<AuthRoute />}>
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/oauth-callback" element={<OAuthCallback />} />
          <Route path="/forget-password" element={<ForgetPassword />} />
          <Route path="/Reset-password" element={<ResetPassword />} />
          <Route path="/new-verify/:email" element={<CheckMail />} />
          <Route path="/email/verified" element={<EmailVerified />} />
          <Route path="/signup" element={<SignUp />} />
        </Route>
        <Route path="/" element={<ProtectedRoute />}>
          <Route path="/onboarding" element={<OnBoardingPage />} />
          <Route path="/" element={<Dashboard />}>
            <Route index path="/" element={<NoDashBoard />} />
            <Route path="/inbox" element={<InboxPage />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/tasks" element={<TaskPage />} />
            <Route path="/docs" element={<DocumentsPage />} />
            <Route path="/account-settings" element={<AccountSettingsPage />} />
            <Route path="/project-detail/:id" element={<ProjectDetail />} />
            <Route path="/workspace/general" element={<General />} />
            <Route path="/workspace/member" element={<Member />} />
            <Route path="/workspace/notification" element={<Notification />} />
          </Route>
          <Route path="/invitation" element={<Invitation />} />
          <Route
            path="/create/workspace"
            element={<CreateYourWorkSpaceStep />}
          />
        </Route>
        <Route
          path="/workspace-invite/:workspaceName/:invitedBy"
          element={<WorkSpaceInvite />}
        />
      </Routes>
    </TourProvider>
  );
}

const ProtectedRoute = () => {
  const { isSignedIn, isLoaded: authLoaded } = useAuth();
  console.log("🚀 ~ ProtectedRoute ~ isSignedIn:", isSignedIn);
  console.log("🚀 ~ ProtectedRoute ~ authLoaded:", authLoaded);
  const { user } = useUser();
  console.log("🚀 ~ ProtectedRoute ~ user:", user);
  const location = useLocation();

  if (!authLoaded) return <Loadingdata />;
  if (!isSignedIn) return <Navigate to="/sign-in" />;

  const isOnboardingIncomplete =
    location.pathname !== "/onboarding" &&
    (!user?.publicMetadata?.step || Number(user.publicMetadata.step) < 1);

  if (isOnboardingIncomplete) {
    return <Navigate to="/onboarding" />;
  }

  if (
    location.pathname !== "/onboarding" &&
    location.pathname !== "/create/workspace" &&
    user.organizationMemberships.length === 0
  ) {
    return <Navigate to="/create/workspace" />;
  }

  return <Outlet />;
};

const AuthRoute = () => {
  const { isSignedIn, isLoaded: authLoaded } = useAuth();

  if (!authLoaded) return <Loadingdata />;

  return isSignedIn ? <Navigate to="/" /> : <Outlet />;
};
