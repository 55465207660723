import React, { useEffect, useState } from "react";
import Topbar from "../../components/Topbar";
import classicon from "../../assets/images/icons/class-room-icon.svg";
import { BsThreeDots } from "react-icons/bs";
import { FaRegComment } from "react-icons/fa6";
import { GrAttachment } from "react-icons/gr";
import CreateProjectModal from "../../components/CreateProjectModal";
import NoProjectFound from "../../components/NoProjectFound";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  assignProjectToUsersAsyncThunk,
  deleteProjectAsyncThunk,
  editProjectAsyncThunk,
  getProjectsAsyncThunk,
  getWorkSpaceAsyncThunk,
  unAssignProjectToUsersAsyncThunk,
} from "../../redux/pagesSlices/projectSlice";
import Dropdown from "react-bootstrap/Dropdown";
import { FiEdit } from "react-icons/fi";
import { MdAssignment, MdDelete } from "react-icons/md";
import Loadingdata from "../loadingdata";
import low from "../../assets/images/icons/low.png";
import urgent from "../../assets/images/icons/svg9.svg";
import medium from "../../assets/images/icons/svg7.svg";
import high from "../../assets/images/icons/svg8.svg";
import statusicon2 from "../../assets/images/icons/statusicon2.svg";
import statusicon3 from "../../assets/images/icons/statusicon3.svg";
import statusicon4 from "../../assets/images/icons/statusicon4.svg";
import DeleteModal from "../../components/DeleteModal";
import { handleModel } from "../../redux/layoutSlices/modelSlice";
import todo from "../../assets/images/icons/status1.svg";
import due from "../../assets/images/icons/x-Vector.svg";
import { FcCancel } from "react-icons/fc";
import {
  assignTaskToUsersAsyncThunk,
  getProjectTasksAsyncThunk,
  unAssignTaskToUsersAsyncThunk,
} from "../../redux/pagesSlices/taskSlice";
import { TiTick } from "react-icons/ti";

const initialFormData = {
  name: "",
  description: "",
};

const statusIcon = {
  todo: <img src={todo} alt="todo" />, // React icon
  in_progress: <img src={statusicon2} alt="In progress" />, // Custom SVG icon
  backlog: <img src={statusicon3} alt="Backlog" />, // Custom SVG icon
  done: <img src={statusicon4} alt="Done" />, // Custom SVG icon
  due: <img src={due} alt="Due" />,
};
const priorityIcon = {
  low: <img src={low} alt="Low" />, // React icon
  medium: <img src={medium} alt="Medium" />, // Custom SVG icon
  high: <img src={high} alt="High" />, // Custom SVG icon
  urgent: <img src={urgent} alt="Urgent" />, // Custom SVG icon
};
export default function ProjectsPage() {
  const [showModal, setShowModal] = useState(false);
  const [projectIdToDelete, setProjectIdToDelete] = useState(null);

  const [editId, setEditId] = useState("");
  const id = useParams().id;
  const [editProjectData, setEditProjectData] = useState("");
  const [createProjectModal, setCreateProjectModalShow] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const d = useDispatch();
  const projects = useSelector((state) => state.project.projects);
  console.log("🚀 ~ ProjectsPage ~ projects:", projects);
  const workspace = useSelector((state) => state.project.WorkSpace);
  console.log("🚀 ~ ProjectsPage ~ workspace:", workspace);
  const loading = useSelector(
    (state) => state.project.loadings?.getProjectsAsyncThunk
  );
  const getData = () => {
    d(
      getProjectsAsyncThunk({
        params: { page: 1, limit: 1000 },
      })
    );
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (!workspace.data || workspace.data.length === 0) {
      d(getWorkSpaceAsyncThunk({}));
    }
  }, [d, workspace.data]);

  const handleAssignees = (id, user) => {
    d(
      assignProjectToUsersAsyncThunk({
        id,
        data: { assignees: [user.userId] },
        user,
        callBack: () => {
          // d(
          //   getProjectsAsyncThunk({
          //     params: { page: 1, limit: 1000 },
          //   })
          // );
        },
      })
    );
  };

  const handleUnAssignees = (id, userId) => {
    d(
      unAssignProjectToUsersAsyncThunk({
        id,
        data: { assignees: [userId] },
        userId,
        callBack: () => {
          // d(
          //   getProjectsAsyncThunk({
          //     params: { page: 1, limit: 1000 },
          //   })
          // );
        },
      })
    );
  };

  const handleCreateProjectClose = () => {
    console.log("formData", formData);
    setEditId("");
    setEditProjectData(initialFormData);
    setCreateProjectModalShow(false);
  };
  const handleCreateProjectShow = () => setCreateProjectModalShow(true);

  const handelDeleteClick = (id) => {
    setProjectIdToDelete(id);
    setShowModal(true);
  };

  const deleteProject = (id) => {
    d(
      deleteProjectAsyncThunk({
        id: projectIdToDelete,
        callBack: () => {
          getData();
        },
      })
    );
  };

  const editProject = (item) => {
    d(
      handleModel({
        model: "CreateProjectModal",
        state: true,
        args: {
          editId: item?.id,
          editProjectData: item,
        },
      })
    );
  };

  const handleUpdate = (id, key, value) => {
    d(
      editProjectAsyncThunk({
        id,
        data: {
          [key]: value,
        },
        callBack: () => {
          // getData();
        },
      })
    );
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  return (
    <div className="projects-page h-100">
      <Topbar handleCreateProjectShow={handleCreateProjectShow} />
      {loading ? (
        <>
          <Loadingdata />
        </>
      ) : (
        <>
          {!projects?.results?.length ? (
            <NoProjectFound  handleCreateProjectShow={handleCreateProjectShow} />
          ) : (
            <div class="container-fluid">
              <div class="task-table-container" style={{ minHeight: "250px" }}>
                <table class="task-table">
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Assignee</th>
                      <th>Due Date</th>
                      <th>Status</th>
                      <th>Comments</th>
                      <th>Priority</th>
                      <th>Attachment</th>
                      <th>Progress</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {projects?.results?.map((item, key) => (
                      <tr key={key}>
                        <td>
                          <div className="icon-wrapper">
                            <img
                              src={item?.icon || classicon}
                              alt="classicon"
                            />
                            <Link to={`/project-detail/${item?.id}`}>
                              {item?.name}
                            </Link>
                          </div>
                        </td>
                        <td className="assignee">
                          <Dropdown
                            onSelect={(e) =>
                              JSON.parse(e).type
                                ? handleUnAssignees(
                                    item.id,
                                    JSON.parse(e)?.user?.userId
                                  )
                                : handleAssignees(item.id, JSON.parse(e).user)
                            }
                            className="dropdown-wraper project-assign-dropdown"
                          >
                            <Dropdown.Toggle>
                              {item.assignees && item.assignees.length > 0 ? (
                                <>
                                  {item.assignees
                                    .slice(0, 3)
                                    .map((assignee, idx) => (
                                      <img
                                        key={idx}
                                        src={assignee?.user?.picture}
                                        alt="Assignee"
                                      />
                                    ))}
                                  {item.assignees.length > 3 && (
                                    <div className="avatar-count">
                                      <strong>
                                        <span>
                                          +{item.assignees.length - 3}
                                        </span>
                                      </strong>
                                    </div>
                                  )}
                                </>
                              ) : (
                                <div className="assignee-images">
                                  <FcCancel size={40} />
                                </div>
                              )}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {workspace?.results?.map((assignee, key) => (
                                <Dropdown.Item
                                  key={assignee?.userId}
                                  eventKey={JSON.stringify({
                                    user: assignee,
                                    type: item?.assignees?.some(
                                      (ite) => assignee?.userId === ite?.userId
                                    ),
                                  })}
                                >
                                  <div className="status-icon">
                                    <img
                                      src={assignee?.picture}
                                      alt="Assignee"
                                    />
                                    <p>{assignee?.displayName}</p>
                                  </div>
                                  {item?.assignees?.some(
                                    (ite) => assignee?.userId === ite?.userId
                                  ) ? (
                                    <>
                                      <TiTick color="green" size={20} />
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                        <td>{formatDate(item?.dueDate)}</td>

                        <td>
                          <Dropdown
                            onSelect={(e) => handleUpdate(item.id, "status", e)}
                            className="dropdown-wraper"
                          >
                            <Dropdown.Toggle className="status-icon">
                              {statusIcon[item.status]}
                              <p>
                                {item.status === "in_progress"
                                  ? "In Progress"
                                  : item.status}
                              </p>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu-right">
                              <Dropdown.Item eventKey={"todo"}>
                                <div className="status-icon">
                                  {statusIcon["todo"]}
                                  <p>todo</p>
                                </div>
                              </Dropdown.Item>
                              <Dropdown.Item eventKey={"in_progress"}>
                                <div className="status-icon">
                                  {statusIcon["in_progress"]}
                                  <p>In progress</p>
                                </div>
                              </Dropdown.Item>

                              <Dropdown.Item eventKey={"backlog"}>
                                <div className="status-icon">
                                  {statusIcon["backlog"]}
                                  <p>Backlog</p>
                                </div>
                              </Dropdown.Item>
                              <Dropdown.Item eventKey={"done"}>
                                <div className="status-icon">
                                  {statusIcon["done"]}
                                  <p>done</p>
                                </div>
                              </Dropdown.Item>
                              <Dropdown.Item eventKey={"due"}>
                                <div className="status-icon">
                                  {statusIcon["due"]}
                                  <p>Due</p>
                                </div>
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                        <td>
                          <div className="icon-wrapper">
                            <FaRegComment />
                            <p>{item._count?.Comments || 0}</p>
                          </div>
                        </td>
                        <td>
                          <Dropdown
                            onSelect={(e) =>
                              handleUpdate(item.id, "priority", e)
                            }
                            className="dropdown-wraper"
                          >
                            <Dropdown.Toggle className="status-icon">
                              {priorityIcon[item?.priority]}
                              <p className="pb-0">{item?.priority}</p>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu-right">
                              <Dropdown.Item eventKey={"low"}>
                                <div className="status-icon">
                                  <img src={low} alt="low" />
                                  <p>Low</p>
                                </div>
                              </Dropdown.Item>
                              <Dropdown.Item eventKey={"medium"}>
                                <div className="status-icon">
                                  <img src={medium} alt="medium" />
                                  <p>Medium</p>
                                </div>
                              </Dropdown.Item>

                              <Dropdown.Item eventKey={"high"}>
                                <div className="status-icon">
                                  <img src={high} alt="high" />
                                  <p>High</p>
                                </div>
                              </Dropdown.Item>
                              <Dropdown.Item eventKey={"urgent"}>
                                <div className="status-icon">
                                  <img src={urgent} alt="done" />
                                  <p>Urgent</p>
                                </div>
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                        <td>
                          <div className="icon-wrapper">
                            <GrAttachment />
                            <p>{item._count?.ProjectAttachments || 0}</p>
                          </div>
                        </td>
                        <td>
                          <div className="bar-wrapper">
                            <p>
                              {item?._count?.Task
                                ? (
                                    (item?.Task?.length / item?._count?.Task) *
                                    100
                                  ).toFixed(2)
                                : 0}
                              %
                            </p>
                            <div
                              className="progress-bar"
                              role="progressbar"
                              aria-valuenow={
                                item?._count?.Task
                                  ? (
                                      (item?.Task?.length /
                                        item?._count?.Task) *
                                      100
                                    ).toFixed(2)
                                  : 0
                              }
                              aria-valuemin="0"
                              aria-valuemax="100"
                              style={{
                                width:
                                  "" +
                                  (item?._count?.Task
                                    ? (
                                        (item?.Task?.length /
                                          item?._count?.Task) *
                                        100
                                      ).toFixed(2)
                                    : 0) +
                                  "%",
                              }}
                            ></div>
                          </div>
                        </td>
                        <td>
                          <Dropdown className="dropdown-wraper">
                            <Dropdown.Toggle
                              style={{
                                background: "transparent",
                                border: "none",
                                color: "black",
                              }}
                            >
                              <BsThreeDots />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                onClick={() => editProject(item)}
                                eventKey="Todo"
                              >
                                <FiEdit />
                                <span>Edit</span>
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => handelDeleteClick(item?.id)}
                                eventKey="Todo"
                                className="delete"
                              >
                                <MdDelete />
                                <span className="delete">Delete</span>
                              </Dropdown.Item>

                              {/* <Dropdown.Item
                                eventKey="2"
                                onClick={() => AssignTaskToUser(item)}
                              >
                                {" "}
                                <MdAssignment />
                                <span>Assign</span>
                              </Dropdown.Item> */}
                            </Dropdown.Menu>
                          </Dropdown>
                          <DeleteModal
                            show={showModal}
                            handleClose={() => setShowModal(false)}
                            onConfirmDelete={deleteProject}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </>
      )}

      <CreateProjectModal
        editId={editId}
        handleCreateProjectClose={handleCreateProjectClose}
        setEditProjectData={setEditProjectData}
        editProjectData={editProjectData}
        setFormData={setFormData}
        formData={formData}
        createProjectModal={createProjectModal}
        setCreateProjectModalShow={setCreateProjectModalShow}
      />
    </div>
  );
}
