import React from 'react'
import doc from "../../src/assets/images/doc.png"
import { FaRegFile } from "react-icons/fa";
import { handleModel } from '../redux/layoutSlices/modelSlice';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

function NoNotes() {
    const d = useDispatch()
  const { id } = useParams();
    const handleSetCreateNoteModal = () => d(
        handleModel({ model: "CreateNoteModal", state: true, args: {id: id} }))

  return (
      <div className='no-project-found'>
          <div className='image-wrapper'>
              <img src={doc} alt="project" />
              <div className='project-content'>
                  <h6>No notes</h6>
                  <p>There aren't currently any notes here.<br></br>
                      Create a note to keep track of important<br></br>
                      information.</p>
                  <div className="project-icon">
                      <button onClick={handleSetCreateNoteModal} className='btn-style'><FaRegFile /> Create note</button>
                  </div>
              </div>
          </div>
      </div>
  )
}

export default NoNotes