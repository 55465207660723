import React from "react";
import emojis from "../assets/projectImges.json";
import { FixedSizeGrid as Grid } from "react-window";

const Emojis = ({ onEmojiClick }) => {
  const cellSize = 50; // adjust size as needed

  return (
    <Grid
      className="emojis-container"
      columnCount={10} // adjust columns as needed
      columnWidth={cellSize}
      height={300} // adjust height to limit visible rows
      rowCount={Math.ceil(emojis.length / 10)}
      rowHeight={cellSize}
      width={500} // adjust width for the grid
    >
      {({ columnIndex, rowIndex, style }) => {
        const emojiIndex = rowIndex * 10 + columnIndex;
        const emojiObj = emojis[emojiIndex];
        if (!emojiObj) return null;

        return (
          <div
            className="single-emoji"
            key={emojiObj}
            onClick={() => onEmojiClick(emojiObj)}
            style={{ ...style, cursor: "pointer" }}
          >
            <img src={emojiObj} loading="lazy" alt="emoji" />
          </div>
        );
      }}
    </Grid>
  );
};

export default Emojis;
