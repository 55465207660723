import { APIurls } from "../constants.js";
import apiService from ".";

export const ApiRequests = {
  //user Authentication
  login: async (data) => await apiService.post(APIurls.login, data),
  logout: async (data) => await apiService.post(APIurls.logout, data),
  register: async (data) => await apiService.post(APIurls.register, data),
  authenticate: async () => await apiService.get(APIurls.authenticate),
  refreshTokens: async (data) =>
    await apiService.post(APIurls.refreshTokens, data),

  revokeToken: async (data) => await apiService.post(APIurls.revokeToken, data),

  updateMetadata: async (data) =>
    await apiService.post(APIurls.userMetadata, data),

  getProfile: async (id) => await apiService.get(APIurls.profile),

  // workspace
  createWorkSpace: async (data) =>
    await apiService.post(APIurls.workSpaces, data),
  inviteMember: async (data) => await apiService.post(APIurls.invite, data),
  rejectInvitation: async (data) =>
    await apiService.post(APIurls.invite + "/reject", data),
  deleteWorkSpace: async (id) =>
    await apiService.delete(APIurls.workSpaces + "/" + id),
  // deleteWorkSpace: async ({ id, data }) => await apiService.patch(APIurls.workSpaces + "/" + id, data),
  getWorkSpaces: async (params) =>
    await apiService.get(APIurls.workSpaces, { params }),
  getWorkSpacesByMail: async (params) =>
    await apiService.get(APIurls.bymail, { params }),
  getWorkSpacesMembership: async (params) =>
    await apiService.get(APIurls.membership, { params }),

  createUser: async (data) => await apiService.post(APIurls.users, data),
  getUsers: async (params) => await apiService.get(APIurls.users, { params }),
  getUser: async (id) => await apiService.get(APIurls.users + "/" + id),
  updateUser: async ({ data, id }) =>
    await apiService.patch(APIurls.users + "/" + id, data),
  deleteUser: async (id) => await apiService.delete(APIurls.users + "/" + id),
  getNotifications: async () => await apiService.get(APIurls.users + "/notifications"),
  createLoad: async (data) => await apiService.post(APIurls.loads, data),
  getLoads: async (params) => await apiService.get(APIurls.loads, { params }),
  getLoad: async (id) => await apiService.get(APIurls.loads + "/" + id),
  updateLoad: async ({ data, id }) =>
    await apiService.patch(APIurls.loads + "/" + id, data),
  deleteLoad: async (id) => await apiService.delete(APIurls.loads + "/" + id),

  createLoadStop: async (data) =>
    await apiService.post(APIurls.loadStops, data),
  getLoadStops: async (params) =>
    await apiService.get(APIurls.loadStops, { params }),
  getLoadStop: async (id) => await apiService.get(APIurls.loadStops + "/" + id),
  updateLoadStop: async ({ data, id }) =>
    await apiService.patch(APIurls.loadStops + "/" + id, data),
  deleteLoadStop: async (id) =>
    await apiService.delete(APIurls.loadStops + "/" + id),

  checkin: async (data) => await apiService.get("/checkin"),

  createFleet: async (data) => await apiService.post(APIurls.fleets, data),
  getFleets: async (params) => await apiService.get(APIurls.fleets, { params }),
  getFleet: async (id) => await apiService.get(APIurls.fleets + "/" + id),
  updateFleet: async ({ data, id }) =>
    await apiService.patch(APIurls.fleets + "/" + id, data),
  deleteFleet: async (id) => await apiService.delete(APIurls.fleets + "/" + id),
  // projects

  creatProject: async (data) => await apiService.post(APIurls.project, data),
  getProjectsListing: async (params) =>
    await apiService.get(APIurls.workspaceProject, { params }),

  getProject: async (id) => await apiService.get(APIurls.project + "/" + id),
  updateProject: async ({ data, id }) =>
    await apiService.patch(APIurls.project + "/" + id, data),
  deleteProject: async (id) =>
    await apiService.delete(APIurls.project + "/" + id),
  // WORKSPACE ROUT

  // doc
  creatDocuments: async (data) =>
    await apiService.post(APIurls.documents, data),
  getDocuments: async (params, id) =>
    await apiService.get(APIurls.projectdocuments + "/" + id, { params }),
  updateDocument: async ({ data, id }) =>
    await apiService.patch(APIurls.documents + "/" + id, data),
  deleteDocument: async (id) =>
    await apiService.delete(APIurls.documents + "/" + id),
  getAssignedDocument: async (params) =>
    await apiService.get(APIurls.assignDocuments, { params }),

  getWorkSpace: async (params) =>
    await apiService.get(APIurls.workSpacesCount, { params }),
  //TASK
  creatTask: async (data) => await apiService.post(APIurls.task, data),
  getTasks: async (params) => await apiService.get(APIurls.task, { params }),
  getProjectTasks: async (params, id) =>
    await apiService.get(APIurls.projectTask + "/" + id, { params }),
  updateTask: async ({ data, id }) =>
    await apiService.patch(APIurls.task + "/" + id, data),
  deleteTask: async (id) => await apiService.delete(APIurls.task + "/" + id),
  assignTask: async (params) =>
    await apiService.get(APIurls.assignTasks, params),
  assignTaskToUsers: async (id, data) =>
    await apiService.patch(APIurls.task + "/" + id + "/assign", data),
  unAssignTaskToUsers: async (id, data) =>
    await apiService.patch(APIurls.task + "/" + id + "/unassign", data),
  assignProjectToUsers: async (id, data) =>
    await apiService.patch(APIurls.project + "/" + id + "/assign", data),
  unAssignProjectToUsers: async (id, data) =>
    await apiService.patch(APIurls.project + "/" + id + "/unassign", data),
  
  //comments
  createComment: async (data) =>
    await apiService.post(APIurls.comments, data),
  getComments: async (params) =>
    await apiService.get(APIurls.comments, { params }),
  createNote: async (data) =>
    await apiService.post(APIurls.notes, data),
  getNotes: async (id, params) =>
    await apiService.get(APIurls.notes + `/project/${id}`, { params }),
  updateNote: async (id, data) =>
    await apiService.patch(APIurls.notes + `/${id}`, data),
  deleteNote: async (id) =>
    await apiService.delete(APIurls.notes + `/${id}`),
};
