import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import { ApiRequests } from "../../service/ApiRequests";
import {
  catchAsync,
  handleLoadingErrorParamsForAsycThunk,
  reduxToolKitCaseBuilder,
} from "../../helpers/detectError";
import { toast } from "react-toastify";

export const createNoteAsyncThunk = createAsyncThunk(
  "note/createNoteAsyncThunk",
  catchAsync(async ({ data, callBack }, { dispatch, getState }) => {
    console.log('data  ', data)
    const response = await ApiRequests.createNote(data);

    if (response.status === 201) {
      toast.success("Note Created Successfully!");
    }
    if (callBack) callBack();
    return response?.data;
  })
);

export const editNoteAsyncThunk = createAsyncThunk(
  "note/editNoteAsyncThunk",
  catchAsync(async ({ id, data, callBack }, { dispatch, getState }) => {
    const response = await ApiRequests.updateNote(id, data);
    if (response.status === 200) {
      toast.success("Note Updated Successfully!");
    }
    if (callBack) callBack();
    return response?.data;
  })
);

export const getNotesAsyncThunk = createAsyncThunk(
  "note/getNotesAsyncThunk",
  catchAsync(async ({ id, params }, { dispatch, getState }) => {
    const response = await ApiRequests.getNotes(id, params);
    return response?.data;
  })
);


export const deleteNoteAsyncThunk = createAsyncThunk(
  "note/deleteNoteAsyncThunk",
  catchAsync(async ({ id, callBack }, { dispatch, getState }) => {
    const response = await ApiRequests.deleteNote(id);

    if (response.status === 204) {
      toast.success("Note Deleted Successfully!");
    }
    if (callBack) callBack();
    return response?.data;
  })
);



///////////////////////////////////////////////////

const initialState = {
  Notes: {
    page: 0,
    results: [],
    totalPages: 1,
  },
  errors: {},
  loadings: {},
  errorMessages: {},
  errorCodes: {},
  paramsForThunk: {},
  search: null,
  order: "asce",
  userIds: [],
};

const workspaceSlice = createSlice({
  name: "workspace",
  initialState,
  reducers: {
    setSearchValue(state, action) {
      state.search = action.payload;
    },
    setOrderValue(state, action) {
      state.order = action.payload;
    },
    storeUserIds: (state, action) => {
      state.userIds = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNotesAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.page > 1) {
          state.Notes = {
            ...action.payload,
            Notes: state?.Notes?.results?.concat(action?.payload?.results),
          };
        } else {
          state.Notes = action.payload;
        }
      })
      .addCase(deleteNoteAsyncThunk.fulfilled, (state, action) => {
        state.Notes = {
          ...state.Notes,
          totalResults: state.Notes?.totalResults - 1,
          results: state.Notes?.results.filter((e) => e.id !== action.payload),
        };
      })
      // im using addMatcher to manage the asyncthunksMehtod actions like fullfilled,pending,rejected and also to manage the errors loading and error messages and async params
      .addMatcher(
        // isAsyncThunk will run when the action is an asyncthunk exists from giver asycntthunks
        isAnyOf(
          // reduxToolKitCaseBuilder helper make fullfilled, pending, and rejected cases
          ...reduxToolKitCaseBuilder([
            createNoteAsyncThunk,
            getNotesAsyncThunk,
          ])
        ),
        handleLoadingErrorParamsForAsycThunk
      );
  },
});

export default workspaceSlice.reducer;
export const {
  setLoading,
  setSearchValue,
  setCategoryValue,
  setOrderValue,
  storeUserIds,
  setSelectedWorkspace,
} = workspaceSlice.actions;
