import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import { ApiRequests } from "../../service/ApiRequests";
import {
  catchAsync,
  handleLoadingErrorParamsForAsycThunk,
  reduxToolKitCaseBuilder,
} from "../../helpers/detectError";
import { toast } from "react-toastify";
// import { useNavigate } from "react-router-dom";
// import { red } from "@mui/material/colors";

// Start User Slices
///////////////////////////////////////////////////

// GET WORKSPACE

export const createDocumentAsyncThunk = createAsyncThunk(
  "user/createDocumentAsyncThunk",
  catchAsync(async ({ data, callBack }, { dispatch, getState }) => {
    const response = await ApiRequests.creatDocuments(data);
    // if (response.status === 201) {
    //   toast.success("Document Created Successfully!");
    // }
    if (callBack) await callBack();
    return response?.data;
  })
);

export const getDocumentsAsyncThunk = createAsyncThunk(
  "user/getDocumentsAsyncThunk",
  catchAsync(async ({ params, id }, { dispatch, getState }) => {
    const response = await ApiRequests.getDocuments(params, id);
    return response?.data;
  })
);

export const getAssignDocumentsAsyncThunk = createAsyncThunk(
  "user/getAssignDocumentsAsyncThunk",
  catchAsync(async ({ params }, { dispatch, getState }) => {
    const response = await ApiRequests.getAssignedDocument(params);
    return response?.data;
  })
);

export const deleteDocumentsAsyncThunk = createAsyncThunk(
  "task/deleteDocumentsAsyncThunk",
  catchAsync(async ({ id, callBack }, { dispatch, getState }) => {
    const response = await ApiRequests.deleteDocument(id);
    if (response.status === 204) {
      toast.success("Task Deleted Successfully!");
    }
    if (callBack) await callBack();
    return id;
  })
);

///////////////////////////////////////////////////

const initialState = {
  //news states
  documents: {
    page: 0,
    results: [],
    totalPages: 1,
  },

  ProjectTasks: {
    page: 0,
    results: [],
    totalPages: 1,
  },
  Assigntask: {
    page: 0,
    results: [],
    totalPages: 1,
  },
  assignDocuments: {
    page: 0,
    results: [],
    totalPages: 1,
  },
  story: null,
  assets: null,
  asset: null,
  listings: {
    page: 0,
    results: [],
    totalPages: 1,
  },
  // manager states
  errors: {},
  loadings: {},
  errorMessages: {},
  errorCodes: {},
  paramsForThunk: {},
  search: null,
  categoryId: null,
  categories: [],
  order: "asce",
  userIds: [],
};

const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setSearchValue(state, action) {
      state.search = action.payload;
    },
    setCategoryValue(state, action) {
      state.categoryId = action.payload;
    },
    setOrderValue(state, action) {
      state.order = action.payload;
    },
    storeUserIds: (state, action) => {
      state.userIds = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //

      .addCase(getDocumentsAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.page > 1) {
          state.documents = {
            ...action.payload,
            documents: state?.documents?.results.concat(
              action?.payload?.results
            ),
          };
        } else {
          state.documents = action.payload;
        }
      })

      .addCase(getAssignDocumentsAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.page > 1) {
          state.assignDocuments = {
            ...action.payload,
            assignDocuments: state?.assignDocuments?.results.concat(
              action?.payload?.results
            ),
          };
        } else {
          state.assignDocuments = action.payload;
        }
      })

      // im using addMatcher to manage the asyncthunksMehtod actions like fullfilled,pending,rejected and also to manage the errors loading and error messages and async params
      .addMatcher(
        // isAsyncThunk will run when the action is an asyncthunk exists from giver asycntthunks
        isAnyOf(
          // reduxToolKitCaseBuilder helper make fullfilled, pending, and rejected cases
          ...reduxToolKitCaseBuilder([
            createDocumentAsyncThunk,
            getDocumentsAsyncThunk,
            deleteDocumentsAsyncThunk,
            getAssignDocumentsAsyncThunk,
          ])
        ),
        handleLoadingErrorParamsForAsycThunk
      );
  },
});

export default userSlice.reducer;
export const {
  setLoading,
  setSearchValue,
  setCategoryValue,
  setOrderValue,
  storeUserIds,
} = userSlice.actions;
